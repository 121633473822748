import { Duration, LocalDateTime } from "js-joda";

export const lt  = ( a: Duration,  b: Duration ) => a.compareTo( b ) === -1;
export const eq  = ( a: Duration,  b: Duration ) => a.compareTo( b ) ===  0; //oh my god
export const gt  = ( a: Duration,  b: Duration ) => a.compareTo( b ) ===  1;

const maxReducer = ( a: Duration,  b: Duration ) => a.compareTo( b ) > 0 ? a : b;
const minReducer = ( a: Duration,  b: Duration ) => a.compareTo( b ) < 0 ? a : b;

export const min = ( ...durs: Duration[] ) => durs.reduce( minReducer, durs[0] );
export const max = ( ...durs: Duration[] ) => durs.reduce( maxReducer, durs[0] );

//compound ones
export const lte = ( a: Duration,  b: Duration ) => [   0, -1].includes( a.compareTo( b ) );
export const gte = ( a: Duration,  b: Duration ) => [1, 0    ].includes( a.compareTo( b ) );

export const upTo = ( d: Duration, a: Duration, b: Duration ) => {
    const cmp1 = d.compareTo( a );
    const cmp2 = d.compareTo( b );
    const res  = cmp1 > 0 && cmp2 <= 0;
    return res;
}

//is d between [a, b)?
export const btwn = ( d: Duration, a: Duration, b: Duration ) => {
    const cmp1 = d.compareTo( a );
    const cmp2 = d.compareTo( b );
    const res = cmp1 >= 0 && cmp2 < 0;
    return res;
}

//some convenience functions for durations
export const mins  = ( minutes: number ) => Duration.ofMinutes( minutes );
export const hours = ( hours:   number ) => Duration.ofHours  (   hours );

export const inHours = ( t: LocalDateTime, ha: number, hb: number ) => {
    if( ha < hb ) {
        return t.hour() >= ha && t.hour() <= hb;
    }
    return t.hour() >= ha || t.hour() <= hb;
}