import { Container, ContainerProps } from "react-bootstrap";

interface ContentProps extends ContainerProps {
    className?: string;
}

export function Content( props: ContentProps ) {
    const { children, className = "", ...rest } = { ...props };
    return <Container className={`pt-2 ${className}`} {...rest}>
        {children}
    </Container>
}