//third-party
import { Card } from "react-bootstrap";

//spotsync
import { showMoney } from "../Data/Money";
import { showDate } from "../Data/Date";
import { Subscription, SubscriptionEntry } from "../Data/ApiTypes";

interface CurrentPlanDetailsProps {
    color?:         string;
    subscription:   Subscription;
}

export function CurrentPlanDetails( props: CurrentPlanDetailsProps ) {
    const { color = "primary", subscription } = { ...props };

    return <Card className={`border-2 border-${color} my-1`}>
        <Card.Header className={`text-center bg-${color}-subtle`}>
            Monthly Parking Options
        </Card.Header>
        <Card.Body>
            <div className="text-secondary">
                {subscription.currentEntry.plan.description}
            </div>
            <div className="mt-2 w-auto gap-3" style={{ display: "inline-grid", gridTemplateColumns: "auto 1fr 1fr" }}>
                <div className="text-start d-flex align-items-center">
                    Number of Spots
                </div>
                <div className="text-end">
                    {subscription.currentEntry.quantity}
                </div>
                <div></div>

                <div className="text-start">Start Date</div>
                <div className="text-end">{showDate( subscription.start )}</div>
                <div></div>
                {/* <div className="monthly-sub-info-text text-secondary">The first day you can park.</div>
                <div></div> */}

                {/*
                <div className="monthly-sub-info-text text-secondary">What you will pay today to be able to park until the end of the month.</div>
                <div></div>
                 */}
                <div className="text-start">Monthly Payment</div>
                <div className="text-end fw-bold">{showMoney( subscription.currentEntry.price )}</div>
                {/* <div className="monthly-sub-info-text text-secondary">What you will pay on the next monthly billing date, this pays for the next month.</div>
                <div></div> */}
            </div>
        </Card.Body>
    </Card>;
}