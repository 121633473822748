import { Container, Row, Col } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { ReservationCard } from "./Controls/ReservationCard";
import { useParamIds } from "./Data/Common";

export function UserReservationReceiptPage() {
    const { reservationId } = useParamIds();
    return <Container>
        <Row>
            <Col>
                <PageTitle>Reservation Receipt Page</PageTitle>
                {/* <ReservationCard index={0} 
                                 reservation={}>
                </ReservationCard> */}
                <hr />
                <div>
                    <h4>Payment Summary</h4>
                </div>
            </Col>
        </Row>
    </Container>
}