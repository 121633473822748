import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { useEffect } from "react";

interface ExpDateModalProps {
    show:    boolean;
    setShow: (show: boolean)            => void;
    onSave:  (data: ExpirationDateForm) => void;
}

interface ExpirationDateForm {
    expMonthYear: string;
}

export function ExpDateModal({ show, setShow, onSave }: ExpDateModalProps) {
    const { register,
            formState: { errors },
            handleSubmit,
            reset } = useForm<ExpirationDateForm>({ defaultValues: { expMonthYear: "" } } );
    
    //reset the form each time it is shown
    useEffect( () => { if( show ) { reset(); } }, [show] );

    function submit( data: ExpirationDateForm ) {
        onSave( data );
        setShow( false );
    }

    return (
        <Modal centered show={show} onEscapeKeyDown={() => setShow(false)}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header>
                    <HeaderText>Change Expiration Date</HeaderText>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        formNoValidate
                        maxLength={5}
                        type="text"
                        {...register("expMonthYear", { 
                            required: "Expiration month is required",
                            maxLength: 5,                             
                            pattern: {
                                value: /^(0[1-9]|1[0-2])\/\d\d$/,
                                message: "Please enter a valid month and year"
                            }
                        })}
                        label="Card Expiration"
                        placeholder="MM/YY"                        
                        error={errors.expMonthYear}
                    />                    
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}