import { apiServerName } from "../Data/ApiUrl";

export function BootstrapBreakpointDebugger() {
    return <div className="d-none d-sm-block" style={{
        color:        "white",
        fontFamily:   "monospace",
        fontSize:     "0.85em",
        background:   'rgba(247, 201, 241, 0.25)',
        padding:      '.5rem 0.5rem',
        borderRadius: '10px',
    }}>
        <div className="d-block d-sm-none">             {apiServerName} xs </div>
        <div className="d-none d-sm-block d-md-none">   {apiServerName} sm </div>
        <div className="d-none d-md-block d-lg-none">   {apiServerName} md </div>
        <div className="d-none d-lg-block d-xl-none">   {apiServerName} lg </div>
        <div className="d-none d-xl-block d-xxl-none" > {apiServerName} xl </div>
        <div className="d-none d-xxl-block" >           {apiServerName} xxl</div>
    </div>
}