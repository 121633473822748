import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Invoice, Order           } from "./Data/ApiTypes";
import { PageTitle       } from "./Controls/PageTitle";
import { showMoney       } from "./Data/Money";
import { facilityImg     } from "./Data/Common";
import { showAddressHtml } from "./Data/Location";
import { showOrderType   } from "./Data/Finance";
import { showInstant     } from "./Data/Date";

export function UserInvoicesPage() {
    const nav = useNavigate();
    const invoices = useLoaderData() as Invoice[];
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Invoices
                </PageTitle>
                {invoices.length === 0 && <>
                    You haven't received any invoices
                </>}
                {invoices.map( invoice => {
                    const facility = invoice.facility;
                    return <Card key={invoice.orderId} className="my-2" style={{ cursor: "pointer" }}
                                 onClick={() => nav(`/user/order/${invoice.orderId}`)}>
                        <Card.Header className="text-start fw-bold">
                            Invoice
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                <img className="facility-result-pic"
                                    src={facilityImg( facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div className="grid-area-b">
                                <div className="fw-bold">
                                    {showInstant( invoice.createdWhen, invoice.facility.timeZone )}
                                </div>
                                {showAddressHtml( facility.address )}
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fs-4 fw-bold">
                                    {showMoney( invoice.endingBalance )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    {showInstant( invoice.createdWhen, facility.timeZone )}
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </Container>;
}