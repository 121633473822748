// Third-party libraries
import { useState } from 'react';
import { LocalDate } from "js-joda";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

// SpotSync-related (custom modules)
import { Button } from "./Button";
import { facilityImg } from "../Data/Common";
import { monthlyCheckoutAtom } from "../Data/Atoms";
import { MonthlyParkingDetails } from "./MonthlyParkingDetails";
import { Facility, ListedPlan, PlanInfo } from "../Data/ApiTypes";

interface FacilityPlanDetailsProps {
    facility: Facility;
    start:    LocalDate;
}

export function FacilityPlanDetails( { facility, start }: FacilityPlanDetailsProps ) {
    const nav = useNavigate();
    const [selOpt,          setSelOpt]          = useState<ListedPlan>( facility.listedPlans[0] );
    const [monthlyCheckout, setMonthlyCheckout] = useAtom( monthlyCheckoutAtom );
    const { listedPlans } = facility;

    function continueToCheckoutClick() {
        setMonthlyCheckout( {
            facility: facility,
            start:    start,
            option:   selOpt,
            quantity: 1,
            vehicles: [],
        } );
        nav( "/checkout/monthly" );
    }

    return <div>
        <img className="rounded-1"
            src={facilityImg( facility.images[0].facilityImageId )}
            width="100%"
            style={{ aspectRatio: "16 / 11", objectFit: "cover" }} />
        <Container className="my-2">
            <div>
                <div className="text-center fs-4">
                    {facility.name}
                </div>
                <MonthlyParkingDetails
                    start={start}
                    listedPlans={facility.listedPlans}
                    selOpt={selOpt}
                    setSelOpt={setSelOpt} />
                <hr className="border-primary border-2" />
                <div className="d-flex justify-content-center">
                    <Button className="w-50 my-2" onClick={continueToCheckoutClick}>
                        Book
                    </Button>
                </div>
                <hr className="border-primary border-2" />
                <div className="m-2" style={{ textAlign: "justify" }}>
                    {facility.entryInstructions}
                </div>
                <hr />
                {/* <div className="mt-2">Amenities</div> */}
                <div className="ms-4">
                    {/* {facility.amenities.map( ( amenity, index ) => (
                        <div key={index}>
                            <AmenityIcon amenity={amenity} /> {amenity}
                        </div>
                    ) )} */}
                </div>
            </div>
        </Container>
    </div>;


};