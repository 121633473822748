import { Container, Row, Col, Card, Modal, Table, ListGroup, Badge } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { useParamIds } from "./Data/Common";
import { showFacilityAddress } from "./Data/Location";
import { LocalDateTime } from "js-joda";
import { getStateEnumFromCode, showStateName } from "./Data/UsState";
import { showMoney } from "./Data/Money";
import { useAtomValue } from "jotai";
import { MapAddress } from "./Controls/MapAddress";
import { pluralize } from "./Data/English";
import { X } from "react-bootstrap-icons";
import { add, multiply } from "dinero.js";
import { showDate } from "./Data/Date";
import { Link, useLoaderData } from "react-router-dom";
import { Parker, Subscription, SubscriptionEntry, Vehicle } from "./Data/ApiTypes";
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { Hyperlink } from "./Controls/Hyperlink";
import { AddVehicleModal } from "./Controls/AddVehicleModal";
import { Input } from "./Controls/Input";
import { Form, SubmitHandler, useForm } from "react-hook-form";
import { HeaderText } from "./Controls/HeaderText";
import { Button } from "./Controls/Button";
import { randomInt } from "crypto";
import React from "react";

export interface ReservationVehicleCardProps {
    vehicle: Vehicle;
}

export function ReservationVehicleCard( { vehicle }: ReservationVehicleCardProps ) {
    return <div className="mb-2 m-2">
        <div className="gap-1"
            style={{
                display: "grid",
                alignItems: "center", /* color make model */
                gridTemplateColumns: "min-content 1fr auto"
            }}>
            <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: vehicle.color }} />
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {vehicle.make} {vehicle.model}
                </div>
                <div>
                    {showStateName( getStateEnumFromCode( vehicle.stateCode )! )} {vehicle.licensePlateNumber}
                </div>
            </div>
        </div>
    </div>;
}

export interface InviteParkerModalProps {
    show:    boolean;
    setShow: ( show:         boolean ) => void;
    submit:  ( emailAddress: string  ) => void;
}
export interface InviteParkerForm {
    emailAddress: string;
}

export function InviteParkerModal( { show, setShow, submit }: InviteParkerModalProps ) {
    const { register, handleSubmit, formState: { errors }, reset }
        = useForm<InviteParkerForm>( { defaultValues: { emailAddress: "" }});

    function submitImpl( data: InviteParkerForm  ) {
        setShow( false );
        submit( data.emailAddress );
        reset();
    }

    return <Modal centered show={show} onEscapeKeyDown={ () => setShow( false ) }>
        <form onSubmit={handleSubmit(submitImpl)}>
        <Modal.Header>
            <HeaderText>Invite a Parker</HeaderText>
        </Modal.Header>
        <Modal.Body>
                <p className="text-secondary">
                    This person will be able to add their vehicles to your plan.
                </p>
                <p className="text-secondary">
                    You can remove them (and their vehicles) at any time.
                </p>
                <Input
                    type="email"
                    label="Email"
                    {...register( "emailAddress", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    } )}
                    error={errors.emailAddress} />
        </Modal.Body>
        <Modal.Footer className="d-flex">
            <Button className="flex-grow-1" onClick={ () => setShow( false )}>
                Cancel
            </Button>
            <Button className="flex-grow-1" type="submit">
                Submit
            </Button>
        </Modal.Footer>
        </form>
    </Modal>
}

export interface LittleParker {
    emailAddress: string;
    status:       string;
    vehicleCount: number;
}

export function UserPlanDetailsPage() {
    const { planId } = useParamIds();
    const [parkers, setParkers] = useState<LittleParker[]>( [] );
    const [myVehicles, setMyVehicles] = useState<Vehicle[]>( [] );
    const [showInviteParker, setShowInviteParker] = useState( false );
    const [showAddVehicle, setShowAddVehicle] = useState( false );
    const sub       = useLoaderData() as Subscription;
    const plan      = sub.currentEntry.plan;
    const facility  = sub.facility;
    const unitPrice = sub.currentEntry.price;
    const quantity  = sub.currentEntry.quantity;

    useEffect( () => {
        Api.vehicleList().then(
            res => processResult( res,
                val => setMyVehicles( val ),
                err => console.log( `Didn't retrieve vehicles because: ${err}`) ) ); //don't do anything if they didn't load
    }, [] );

    return <Container fluid>

        <InviteParkerModal show={showInviteParker}
                           setShow={setShowInviteParker}
                           submit={ ( emailAddress ) => {
                                setParkers( [...parkers, {
                                    emailAddress: emailAddress,
                                    status:       "Invite Sent",
                                    vehicleCount: 0
                                } ] );
                           } } />

         <AddVehicleModal show={showAddVehicle}
                          setShow={setShowAddVehicle}
                          onSubmit={ ( v ) => {
                            Api.vehicleAdd( v ).then( res => processResult( res, val => {
                                setMyVehicles( [...myVehicles, val ] );
                            } ) )
                          }} />
        <Row>
            <Col>
                <Container fluid className="g-0">
                    <Row>
                        <Col xs="12">
                            <PageTitle>
                                Monthly Plan at {facility.name}
                            </PageTitle>
                            <div className="fs-6 mt-1 fw-normal text-secondary" style={{ position: "relative", left: "0", top: "-0.5em" }}>
                                    {plan.name}
                            </div>
                            <div className="fs-6 mt-1 fw-normal text-secondary" style={{ position: "relative", left: "0", top: "-0.5em" }}>
                                    {plan.accessHours}
                            </div>
                            <div>
                                <div className="text-secondary">
                                    <MapAddress address={facility.address}>
                                        Get Directions
                                    </MapAddress>
                                </div>
                            </div>
                            <div className="mt-2">
                                Your plan includes <span className="fw-bold">{quantity}</span> spots
                            </div>
                            <div className="mt-2">
                                <Hyperlink to={`/user/plan/${planId}/edit`}>
                                    Add or Remove Spots
                                </Hyperlink>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="mt-2 g-2">
                        <Col xs="12" lg="12">
                            <Card className="h-100">
                                <Card.Header className="fw-bold" style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content" }}>
                                    <div>Parkers</div>
                                    <div></div>
                                    <Link to="" onClick={ () => setShowInviteParker( true )}>Invite Parker</Link>
                                </Card.Header>
                                <Card.Body className="mt-0 py-2">
                                    {parkers.length == 0 && <div className="grid-col-1 text-secondary">
                                        Invite others to this plan to let them manage their own vehicles.
                                    </div>}
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat( 3, 1fr )" }}
                                         className="gap-2">
                                        {parkers.length !== 0 && <>
                                            <div className="text-center fw-bold">Email</div>
                                            <div className="text-center fw-bold">Status</div>
                                            <div className="text-center fw-bold">Vehicles</div>
                                        </>}
                                        {parkers.map( (p, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <div className="text-center">{p.emailAddress}</div>
                                                    <div className="text-center">{p.status}</div>
                                                    <div className="text-center">{p.vehicleCount}</div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
                {/* <Card className="mt-3">
                    <Card.Header className="fw-bold" style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content"}}>
                        <div>Vehicle</div>
                        <div></div>
                        <Link to="" onClick={ () => setShowAddVehicle( true )}>Add Vehicle</Link>
                    </Card.Header>
                    <Card.Body>
                        {<div className="text-secondary">
                            You can add or remove vehicles to this plan at any time.
                        </div>}
                        {myVehicles.map( v => <ReservationVehicleCard vehicle={v} />)}
                    </Card.Body>
                </Card> */}
                <Card className="mt-3">
                    <Card.Header className="fw-bold">
                        History
                    </Card.Header>
                    <Card.Body>
                        {sub.accessPeriods.map( ap => <div>
                            <div className="text-secondary mb-2">
                                Paid {showDate( LocalDateTime.now().toLocalDate() )} using Amex ending in 7000
                            </div>
                            <Container>
                                <Row className="justify-content-start">
                                    <Col xs="6" className="">
                                        <div style={{ display: "inline-grid",
                                            gridTemplateColumns: "repeat( 5, max-content )" }}
                                            className="row-gap-2 column-gap-2">
                                            <div className="fw-bold grid-col-1 text-center">Qty</div>
                                            <div className="fw-bold grid-col-2 text-center"><X /></div>
                                            <div className="fw-bold grid-col-3 text-center">Product</div>
                                            <div className="fw-bold grid-col-4 text-end">Unit Price</div>
                                            <div className="fw-bold grid-col-5 text-end">Amount</div>
                                            <div className="grid-col-1 text-end">
                                                {quantity}
                                            </div>
                                            <div className="grid-col-2">
                                                <X />
                                            </div>
                                            <div className="grid-col-3 text-center">
                                                 {pluralize( quantity, "Spot", "Spots" )}
                                            </div>
                                            <div className="grid-col-4 text-end">
                                                {showMoney( unitPrice )}
                                            </div>
                                            <div className="grid-col-5 text-end">
                                                {showMoney( multiply( unitPrice, quantity ) )}
                                            </div>
                                            <div className="grid-col-4 text-end fw-bold">
                                                Total
                                            </div>
                                            <div className="grid-col-5 text-end">
                                                {showMoney( multiply( unitPrice, quantity ) )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>) }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>;
}
