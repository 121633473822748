import { PaymentMethodResp } from "../Data/Api";

export interface PaymentMethodCardProps {
    paymentMethod: PaymentMethodResp;
    className?:    string;
}

function getCardLogo( cardBrand: string ) {
    switch( cardBrand ) {
        case "Visa":             return "/img/card-logo-visa.svg";
        case "Mastercard":       return "/img/card-logo-mastercard.svg";
        case "American Express": return "/img/card-logo-american-express.svg";
        case "Discover":         return "/img/card-logo-discover.png";
        default:
            return "Err!";
    }
}

export function PaymentMethodCard( props: PaymentMethodCardProps ) {
    const { paymentMethod,
            className = "" } = props;
    return <div className={`${className} credit-card border border-2 border-primary rounded-2 p-3 position-relative text-black`}>
        <div className="grid-area-a align-self-center text-center" style={{ textTransform: "capitalize" }}>
            {paymentMethod.card.brand}
        </div>
        <div className="grid-area-b text-start">Ending in {paymentMethod.card.last4Digits}</div>
        <div className="grid-area-c"></div>
        {false && <div className="position-absolute px-2 py-1 bg-primary rounded-start text-white" style={{ fontSize: "0.70em" ,
            right: "0" , bottom: "7%" }}>
            Default
        </div>}
    </div>
}