import { Col, Container, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { useEffect, useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { stripePromise } from "./Data/ApiStripe";
import { PaymentIntent } from "@stripe/stripe-js";

export interface PaymentStatusProps {
    pmtIntentKey:          string;
    pmtIntentClientSecret: string;
    redirectStatus:        string;
}

export function status( status: PaymentIntent.Status ) {
    switch( status ) {
        case "canceled":                return "Canceled";
        case "processing":              return "Processing";
        case "requires_action":         return "Requires Action";
        case "requires_capture":        return "Hold Successful";
        case "requires_confirmation":   return "Requires Confirmation";
        case "requires_payment_method": return "Requires Payment Method";
        case "succeeded":               return "Succeeded";
        default:                        return "Unrecognized Payment Status";
    }
}

export function description( status: PaymentIntent.Status ) {
    switch( status ) {
        case "canceled":                return ["Canceled"];
        case "processing":              return ["Your payment may take a couple of days to show up in our system."];
        case "requires_action":         return ["You may need to do something to complete your payment."];
        case "requires_capture":        return ["We have placed a hold on your payment method.",
                                                "If a staff member approves your application the payment will go through."];
        case "requires_confirmation":   return ["Requires Confirmation"];
        case "requires_payment_method": return ["Requires Payment Method"];
        case "succeeded":               return ["We have received your payment."];
        default:                        return ["Unrecognized Payment Status"];
    }
}

export function PaymentStatus( { pmtIntentClientSecret }: PaymentStatusProps ) {
    const stripe = useStripe();
    const [msg, setMsg] = useState<string>();
    const [desc, setDesc] = useState<string[]>( [] );

    useEffect( () => {
        if( !stripe ) {
            return;
        }

        // retrieve the paymentintent
        stripe.retrievePaymentIntent( pmtIntentClientSecret )
            .then( ( { paymentIntent } ) => {
                if( !paymentIntent ) {
                    return;
                }
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                setMsg( status( paymentIntent.status ) );
                setDesc( description( paymentIntent.status ) );
            } );
    }, [stripe] );

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    {msg}
                </PageTitle>
                <ul className="list-group list-group-flush">
                    {desc.map( d =>
                        <li className="list-group-item">
                            {d}
                        </li> )}
                </ul>
            </Col>
        </Row>
    </Container>;
}

export function UserPaidPage() {
    //?payment_intent=pi_3QOsYpRR5LM9CxtV1Hv8VGP5&payment_intent_client_secret=pi_3QOsYpRR5LM9CxtV1Hv8VGP5_secret_lOuQxrDKeH6lkDQXFoMDfrg15&redirect_status=succeeded
    const [searchParams] = useSearchParams();
    const pmtIntentKey          = searchParams.get( "payment_intent"               )!;
    const pmtIntentClientSecret = searchParams.get( "payment_intent_client_secret" )!;
    const redirectStatus        = searchParams.get( "redirect_status"              )!;

    return <Elements stripe={stripePromise}>
        <PaymentStatus pmtIntentClientSecret={pmtIntentClientSecret}
                       pmtIntentKey={pmtIntentKey}
                       redirectStatus={redirectStatus} />
    </Elements>
}