export let apiServerName = "local";
const detectServ = ( url: string ) => {
    const loc = url;
    if( loc.includes( "dev.spotsync.com" ) ) {
        apiServerName = "dev";
        return 'https://api.dev.spotsync.com/connect';
    }
    if( loc.includes( "spotsync.com" ) ) {
        apiServerName = "prod";
        return 'https://api.spotsync.com/connect';
    }
    apiServerName = "local";
    return "https://local.dev:7063/connect";
}

const serv = detectServ( window.location.href );
const url = ( tail: string ) => { return `${serv}${tail}`; }

export class ApiUrl {
    //operator
    static opDetails() { return url( `/` ); }

    //parker
    static amILoggedIn()                              { return url( `/auth/`                             ); } //GET
    static signUp()                                   { return url( `/auth/signup`                       ); } //POST
    static logIn()                                    { return url( `/auth/login`                        ); } //POST
    static logOut()                                   { return url( `/auth/logout`                       ); } //POST
    static forgotPassword()                           { return url( `/auth/forgot`                       ); } //POST
    static resetCodeCheck()                           { return url( `/auth/check`                        ); } //POST
    static resetPassword()                            { return url( `/auth/reset`                        ); } //POST
    static changeEmailAddress()                       { return url( `/auth/email`                        ); } //POST
    static changePassword()                           { return url( `/auth/password`                     ); } //POST
    static deleteAccount()                            { return url( `/auth/delete`                       ); } //GET (retrieves a random secret)
    static deleteAccountConfirm()                     { return url( `/auth/delete-confirm`               ); } //POST

    //parker vehicle functionality
    static vehicleDetails( id: number )               { return url( `/vehicle/${id}`                     ); } //GET
    static vehicleAdd()                               { return url( `/vehicle/add`                       ); } //POST
    static vehicleList()                              { return url( `/vehicle/`                          ); } //GET
    static vehicleUpdate( id: number )                { return url( `/vehicle/${id}`                     ); } //POST
    static vehicleDelete( id: number )                { return url( `/vehicle/${id}/delete`              ); } //POST

    //user reservations
    static reservationList()                          { return url( `/reservation`                       ); } //GET
    static reservationDetails( id: number )           { return url( `/reservation/${id}`                 ); } //GET
    static reservationInit()                          { return url( `/reservation/init`                  ); } //POST
    static reservationConfirm()                       { return url( `/reservation/confirm`               ); } //POST
    static reservationCancel(  id: number )           { return url( `/reservation/${id}/cancel`          ); } //POST

    //TODO: there is some ambiguity with these URLs, plan doesn't have the same meaning in subscriptionDetails and in planTerms
    static subscriptionList()                         { return url( `/plan`                              ); } //GET
    static subscriptionDetails( id: number )          { return url( `/plan/${id}`                        ); } //GET
    static planTerms( id: number )                    { return url( `/plan/${id}/terms`                  ); } //GET

    //created during checkout
    static subscriptionInit()                         { return url( `/plan/init`                         ); } //POST
    static subscriptionConfirm()                      { return url( `/plan/confirm`                      ); } //POST
    static subscriptionCancel( id: number )           { return url( `/plan/${id}/cancel`                 ); } //GET

    //facilities
    static facilitySearch()                           { return url( `/facility`                          ); } //POST
    static facilityDetails( id: number )              { return url( `/facility/${id}`                    ); } //GET

    //images
    static imageGet( imgId: number ) { return url( `/img/${imgId}` ); } //GET

    //finance
    static facilityParkerBalanceList()                { return url(`/finance/balance`);          } // POST
    static settleInit()                               { return url(`/finance/settle/init`);      } // POST
    static orderPayInit()                             { return url(`/finance/order/init`);       } // POST

    static orderList()                                { return url(`/finance/order`);            } // GET
    static orderDetails( id: number )                 { return url(`/finance/order/${id}`);      } // GET

    static invoiceList()                              { return url(`/finance/invoice`);          } // GET
    static invoiceDetails( id: number )               { return url(`/finance/invoice/${id}`);    } // GET

    static paymentList()                              { return url(`/finance/payment`);          } // GET
    static paymentDetails( id: number )               { return url(`/finance/payment/${id}`);    } // GET

    //e-commerce
    static paymentMethodSetAsDefault()                { return url( `/stripe/payment-method-set-default` ); }
    static stripePaymentMethodList()                  { return url( `/stripe/payment-method`             ); } //GET
    static stripePaymentMethodDetails( id: string )   { return url( `/stripe/payment-method/${id}`       ); } //GET
    static stripeSubscriptionCreate()                 { return url( `/stripe/create-subscription-intent` ); } //See Footnote #1
    static stripeConfig()                             { return url( `/stripe/config`                     ); } //POST
    static setupIntentCreate()                        { return url( `/stripe/create-setup-intent`        ); } //POST
    static stripePaymentMethodUpdateExp()             { return url( `/stripe/payment-method`             ); } //POST
    static stripePaymentMethodUpdateBillindAddress()  { return url( `/stripe/payment-method`             ); } //POST
    static stripePaymentMethodDelete()                { return url( `/stripe/payment-method`             ); } //POST

    //Footnotes
    //  #1: Why aren't these urls operating at a higher level?  For example, why not subscribeToMonthly plan?
    //      It's because we're using Stripe elements and it seems this is the way they like to do things
    //      We'll still have our stuff such as subscribeToMonthlyPlan but it will usually be the last step (I think)
}