import { Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { BillingAddress } from "./Data/FakeData";
import { Link, useLoaderData } from "react-router-dom";
import { useState } from "react";
import { DeletePaymentMethodModal } from "./UserBillingPageDeletePaymentMethodModal";
import { PaymentMethodCard } from "./Controls/PaymentMethodCard";
import { ExpDateModal } from "./UserBillingDetailsPageExpDateModal";
import { BillingAddressModal } from "./UserBillingDetailsPageBillingAddressModal";
import { PaymentMethodResp } from "./Data/Api";

export function UserBillingDetailsPage() {        
    const paymentDetails = useLoaderData() as PaymentMethodResp;
    const [ cardInfo,         setCardInfo         ] = useState<PaymentMethodResp>( paymentDetails );
    const [ showDelete,       setShowDelete       ] = useState( false );
    const [ showExpDateModal, setShowExpDateModal ] = useState( false );
    const [ showAddressModal, setShowAddressModal ] = useState( false );

    const handleExpirationSave = ( { expMonthYear }: { expMonthYear: string } ) => {
        let arr = expMonthYear.split( "/" );
        // setCardInfo( prevInfo => ( {
        //     ...prevInfo,
        //     expirationMonth: arr[0],
        //     expirationYear:  arr[1]
        // } ) );
    };

    const handleAddressSave = (data: BillingAddress) => {
        // setCardInfo( prevInfo => ( {
        //     ...prevInfo,
        //     ...data
        // } ) );
    };

    //this payment method is on file for these subscriptions
    return <Container fluid>
        <ExpDateModal 
            show={showExpDateModal}
            setShow={setShowExpDateModal}
            onSave={handleExpirationSave} />

        <BillingAddressModal
            show={showAddressModal}
            setShow={setShowAddressModal}
            onSave={handleAddressSave} />

        <DeletePaymentMethodModal show={showDelete} setShow={setShowDelete} />
        <Row>
            <Col md="12">
                <PageTitle>
                    Saved Card
                </PageTitle>
                <Container>
                    {cardInfo && <Row className="gap-2">
                        <Col className="g-0" xs="auto">
                            <PaymentMethodCard paymentMethod={cardInfo} />
                        </Col>
                        <Col className="g-0 lh-lg" xs="auto">
                            {/* {!cardInfo.isDefault && <div>
                                <Link to="">
                                    Set as Default
                                </Link>
                            </div>} */}
                            <div>
                                <Link to="" onClick={ () => setShowExpDateModal( true )}>
                                    Change Expiration Date
                                </Link>
                            </div>
                            <div>
                                <Link to="" onClick={ () => setShowAddressModal( true )}>
                                    Change Billing Address
                                </Link>
                            </div>
                            <div>
                                <Link to="" onClick={ () => setShowDelete( true ) }>
                                    Delete
                                </Link>
                            </div>
                        </Col>
                    </Row>}
                    <Row>
                        <Col className="g-0 mt-2">
                            {/* {cardInfo.isDefault && <div className="text-primary">
                                This is your default payment-method.
                            </div>} */}
                            <div className="mt-2 text-secondary">
                                {/* TODO: look up ways to show info about where this payment method is used */}
                                {/* This payment method is not linked with any subscriptions. */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col>

            </Col>
        </Row>
    </Container>;
}