import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { HeaderText } from "./Controls/HeaderText";
import { useState } from "react";
import { ArrowRepeat, InfoCircle, PersonPlus, PersonX, PlusSlashMinus } from "react-bootstrap-icons";
import { Button } from "./Controls/Button";

export interface GroupPlansModalProps {
    show:    boolean;
    setShow: ( b: boolean ) => void;
}

export function GroupPlansModal( props: GroupPlansModalProps ) {
    const { show, setShow } = props;

    const features = [
        { icon: <ArrowRepeat    className="fs-4 me-2" />, text: "Convert any monthly plan into a group plan"        },
        { icon: <PlusSlashMinus className="fs-4 me-2" />, text: "Increase or decrease the spaces you need"          },
        { icon: <PersonPlus     className="fs-4 me-2" />, text: "Invite people to manage vehicles for their spaces" },
        { icon: <PersonX        className="fs-4 me-2" />, text: "Remove people from spaces at any time"             }
    ];

    return (
        <Modal show={show} centered>
            <Modal.Header>
                <HeaderText>Group Plans</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <p>Manage your organization's parking with Group Plans</p>
                <ul className="mb-4">
                    {features.map((feature, index) => (
                        <div key={index} className="mb-4 d-flex align-items-center">
                            {feature.icon} {feature.text}
                        </div>
                    ))}
                </ul>
                <Form>
                    <div className="d-flex justify-content-end">
                        <Button type="button" onClick={() => setShow(false)}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export function UserGroupPlansPage() {
    const [ showHelp, setShowHelp ] = useState( false );
    return <Container>
        <GroupPlansModal show={showHelp} setShow={setShowHelp} />
        <Row>
            <Col>
                <div>
                    <PageTitle>
                        Group Plans <InfoCircle className="fs-5" color="purple" cursor="pointer" onClick={ () => setShowHelp( true ) } />
                    </PageTitle>
                    
                </div>
                <div className="text-secondary">
                    Use group plans to manage parking for a group of people.                    
                </div>
            </Col>
        </Row>
    </Container>
}