import { Card, Col, Container, Row } from "react-bootstrap";
import { useRouteError } from "react-router-dom";

export function ErrorPage() {
    const err = useRouteError() as Error;
    return <Container fluid className="p-4">
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        An Unexpected Error Occurred
                    </Card.Header>
                    <Card.Body>
                        {err.message}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>;
}