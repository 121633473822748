import { forwardRef, ReactNode } from "react";
import { Form, FormSelectProps, InputGroup } from "react-bootstrap";
import { FieldError } from "react-hook-form";

interface SelectProps extends FormSelectProps {
    className?:    string;
    label:         string;
    icon?:         ReactNode;
    placeholder?:  string;
    explanation?:  string;
    error?:        FieldError | undefined;
    children:      ReactNode;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    ( { className = "mb-3",
        label,
        icon,
        error,
        placeholder = "",
        explanation = "",
        children,
        ...props
    }, ref ) => {
        return <Form.Group className={className}>
            <Form.Label className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>
                    {icon}
                </InputGroup.Text>}
                <Form.Select
                    {...props}
                    ref={ref}>
                        {children}
                </Form.Select>
            </InputGroup>
            {explanation &&
                <Form.Text className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);