import { loadStripe } from "@stripe/stripe-js";

function stripeLoader( cfg: StripeConfig ) {
    return loadStripe( cfg.publishableKey, { stripeAccount: cfg.stripeAccount } )
}

interface StripeConfig {
    publishableKey: string; //the publishable key for the primary stripe account
    stripeAccount: string; //the connected account
}

function getStripeConfigFromDomain( hostname: string ): StripeConfig {
    const stripeConfig = {
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        stripeAccount:  process.env.REACT_APP_STRIPE_ACCOUNT_KEY!
    };
    return stripeConfig;
}

export const stripePromise = stripeLoader( getStripeConfigFromDomain( window.location.hostname ) );
