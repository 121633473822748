import { Currency, Dinero, dinero, maximum, minimum, toDecimal, toUnits, add, subtract, toSnapshot } from 'dinero.js';
import { USD } from '@dinero.js/currencies';

const transformer = ( { value, currency }: { value: Dinero<number>, currency: Currency<number>  } ) => {
    return `${currency.code} ${Number(value).toFixed(2)}`;
};

export type  Money          = Dinero<number>;
export const cents          = ( centsValue:   number           ) => dinero( { amount: centsValue, currency: USD } );
export const toCents        = ( money:        Dinero<number>   ) => toSnapshot( money ).amount;
export const min            = ( monies:       Dinero<number>[] ) => minimum( monies );
export const max            = ( monies:       Dinero<number>[] ) => maximum( monies );
export const showMoneyShort = ( money:        Dinero<number>   ) => toUnits( money, ({ value }) => `$${value[0]}` );
export const showMoney      = ( money:        Dinero<number>   ) => `$${toDecimal( money )}`;