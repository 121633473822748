//react
import { useState          } from 'react';

//bootstrap
import { Alert             } from 'react-bootstrap';

//js-joda

//stripe
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

//spotsync
import { SettleInitResp } from "./Data/ApiTypes";
import { showMoney      } from "./Data/Money";
import { Button         } from "./Controls/Button";
import { connectUrl     } from './Data/WebUrl';

export function StripeSettleCheckoutForm( { initResp }: { initResp: SettleInitResp } ) {
    const [msg, setMsg] = useState<string>();
    const stripe   = useStripe();
    const elements = useElements();

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();

        if( !stripe || !elements ) {
            return;
        }

        //trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if( submitError ) {
            return;
        }

        const result = await stripe.confirmPayment( {
            elements,
            confirmParams: {
                return_url: connectUrl( `/user/paid` ),
            },
        } );

        if( result.error ) {
            // Show error to your customer (for example, payment details incomplete)
            setMsg( result.error.message );
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="d-flex justify-content-end align-items-center gap-2 mx-0 my-2">
                {msg && <Alert variant="danger" className="m-0 flex-grow-1">
                    {msg}
                </Alert>}
                <Button type="submit" disabled={!stripe} className="px-3">
                        Pay {showMoney( initResp.price )}
                </Button>
            </div>
            <br />

        </form>
};

