import { Container, Row, Col } from 'react-bootstrap';
import { useLoaderData } from "react-router-dom";
import { Operator } from "./Data/ApiTypes";

export interface OpProps { op: Operator; }

//perfect example of stamp coupling on this page
export function AboutPage() {
    const op = useLoaderData() as Operator;
    return (
        <Container className="py-4">
            <CustomJumbotron op={op} />
            <ContentRows op={op} />
            <Footer op={op} />
        </Container>
    );
}

function CustomJumbotron( props: OpProps ) {
    const { op } = props;
    return (
        <div className="p-3 mb-2 bg-body-tertiary rounded-3 border border-primary">
            <Container fluid className="py-3">
                <h1 className="display-5 fw-bold">{op.topTitle}</h1>
                <p className="col-md-8 fs-4">{op.topText}</p>
            </Container>
        </div>
    );
}

function ContentRows( props: OpProps ) {
    const { op } = props;
    return (
        <Row className="g-2">
            <Col md="6" xs="12">
                <div className="h-100 p-5 text-bg-dark rounded-3">
                    <h2>{op.bottomLeftTitle}</h2>
                    <p>{op.bottomLeftText}</p>
                </div>
            </Col>
            <Col md="6" xs="12">
                <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                    <h2>{op.bottomRightTitle}</h2>
                    <p>{op.bottomRightText}</p>
                </div>
            </Col>
        </Row>
    );
}

function Footer( props: OpProps ) {
    const { op } = props;
    return (
        <footer style={{ display: "inline-grid", gridTemplateColumns: "auto auto", columnGap: "0.75em", rowGap: "0.25em" }} 
                className="pt-3 mt-4 text-body-secondary border-top">
            <div>&copy; 2024 {op.name}</div>
            <div></div>

            <div></div>
            <div></div>
            
            <div>{op.emailAddress}</div>
            <div></div>

            <div>{op.phoneNumber}</div>
            <div></div>

            <div>{op.address.addressLine1}</div>
            <div>{op.address.addressLine2}</div>
            <div>{op.address.city}, {op.address.state} {op.address.zipCode}</div>
            <div></div>
        </footer>
    );
}