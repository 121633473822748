import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useAtom                } from "jotai";
import { Button                 } from "./Controls/Button";
import { ReserveInitResp        } from "./Data/ApiTypes";
import { showMoney              } from "./Data/Money";
import { showDuration           } from "./Data/Date";
import { maskAtom, PageMaskOpts } from "./Data/Atoms";
import { Api } from "./Data/Api";
import { connectUrl } from "./Data/WebUrl";

export function StripeHourlyCheckoutForm( { reservationDetails }: { reservationDetails: ReserveInitResp } ) {
    const stripe   = useStripe();
    const elements = useElements();

    const maskOptions: PageMaskOpts = {
        showMask: true,
        header:  <div className="h2">Processing Payment</div>,
        subText: 'Do not close your browser',
    };
    const [, setShowMask] = useAtom( maskAtom );

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();
        if( !stripe || !elements ) {
            return;
        }
        setShowMask( maskOptions );
        //not super essential, but marks it as submitted in our system
        const confirmRes = await Api.reservationConfirm( { orderId: reservationDetails.orderId } );
        const result = await stripe.confirmPayment( {
            elements,
            confirmParams: { return_url: connectUrl( "/user/paid" ) },
        } );

        if( result.error ) {
            // Show error to your customer (for example, payment details incomplete)
            console.log( result.error.message );
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setShowMask(undefined);
    };

    return <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="d-flex justify-content-end">
                <Button type="submit" disabled={!stripe} className="mt-2 px-3">
                        Pay {showMoney( reservationDetails.price )} for {showDuration( reservationDetails.stayDuration )}
                </Button>
            </div>
        </form>
};

