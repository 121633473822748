import { useState } from 'react';
import { Button } from "./Button";
import { Duration, LocalDateTime } from "js-joda";
import { Card, Container } from "react-bootstrap";
import { facilityImg } from "../Data/Common";
import { useNavigate } from "react-router-dom";
import { hourlyCheckoutAtom } from "../Data/Atoms";
import { useAtom } from "jotai";
import { ReservationDetailsHourly } from "./ReservationDetailsHourly";
import { Facility } from "../Data/ApiTypes";

interface FacilityHourlyDetailsProps {
    facility: Facility;
    start:    LocalDateTime;
    end:      LocalDateTime;
}

export function FacilityHourlyDetails( { facility, start, end } : FacilityHourlyDetailsProps ) {
    const nav = useNavigate();
    const [selOpt,         setSelOpt]         = useState<number>( 0 );
    const [hourlyCheckout, setHourlyCheckout] = useAtom( hourlyCheckoutAtom );
    const duration = Duration.between( start, end );
    function continueToCheckoutClick() {
        setHourlyCheckout( { facility: facility, start: start, end: end, vehicles: [] } );
        nav( "/checkout/hourly" );
    }
    return <div>
        <img className="rounded-1"
            src={facilityImg( facility.images[0].storedImageId )}
            width="100%"
            style={{ aspectRatio: "16 / 11", objectFit: "cover" }} />
        <Container className="my-2">
            <div>
                <div className="my-3">
                    <div className="text-center fs-4">
                        {facility.name}
                    </div>
                </div>
                <Card className="border-2 border-primary my-1">
                    <Card.Header className="text-center bg-primary-subtle">
                        Reservation Details
                    </Card.Header>
                    <Card.Body>
                        <ReservationDetailsHourly start={start} end={end} rates={facility.hourlyRates[0]} />
                    </Card.Body>
                </Card>
                <hr className="border-primary border-2" />
                <div className="d-flex justify-content-center">
                    <Button className="w-50 my-2" onClick={continueToCheckoutClick}>
                        Book
                    </Button>
                </div>
                <hr className="border-primary border-2" />
                <div className="m-2" style={{ textAlign: "justify" }}>
                    {facility.entryInstructions}
                </div>
                <hr />
                {/* <div className="mt-2">Amenities</div> */}
                {/* <div className="ms-4">
                    {facility.amenities.map( ( amenity, index ) => (
                        <div key={index}>
                            <AmenityIcon amenity={amenity} /> {amenity}
                        </div>
                    ) )}
                </div> */}
            </div>
        </Container>
    </div>;
};