//third-party
import { Duration, LocalDateTime } from "js-joda";

//spotsync
import { showDate, showDurationShort, showTime } from "../Data/Date";
import { showMoney     } from "../Data/Money";
import { computeCharge } from "../Data/Rates";
import { RateProgram as RateProgram } from "../Data/ApiTypes";

export interface ReservationDetailsHourlyProps {
    rates: RateProgram;
    start: LocalDateTime;
    end:   LocalDateTime;
}

export function ReservationDetailsHourly( props: ReservationDetailsHourlyProps ) {
    const { rates, start, end } = { ...props };
    return <div className="w-auto gap-3" style={{ display: "inline-grid", gridTemplateColumns: "auto 1fr 1fr"}}>
        <div>Duration</div>
        <div className="text-end">{showDurationShort( Duration.between( start, end ) )}</div>
        <div></div>

        <div className="text-start">Check In</div>
        <div className="text-end">{showDate( start.toLocalDate() )}</div>
        <div className="text-end">{showTime( start.toLocalTime() )}</div>

        <div className="text-start">Check Out</div>
        <div className="text-end">{showDate( end.toLocalDate() )}</div>
        <div className="text-end">{showTime( end.toLocalTime() )}</div>
        
        <div className="text-start">Total Price</div>
        <div className="text-end fw-bold">{showMoney( computeCharge( rates, start, end ) )}</div>
        <div></div>
    </div>;
}