import { Container, Row, Col } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { useLoaderData } from "react-router-dom";
import { Facility, ListedPlan, Subscription, SubscriptionEntry, Vehicle } from "./Data/ApiTypes";
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { MonthlyParkingDetails } from "./Controls/MonthlyParkingDetails";
import { parseFacility } from "./Data/ApiParse";
import { LocalDate } from "js-joda";
import { Button } from "./Controls/Button";
import { CurrentPlanDetails } from "./Controls/CurrentPlanDetails";

export function UserPlanDetailsChangePage() {
    const [facility,       setFacility]       = useState<Facility>();
    const [oldMonthlyPlan, setOldMonthlyPlan] = useState<ListedPlan>();
    const [selMonthlyPlan, setSelMonthlyPlan] = useState<ListedPlan>();
    const [myVehicles,     setMyVehicles]     = useState<Vehicle[]>( [] );
    const sub                                 = useLoaderData() as Subscription;
    const [modSub, setModSub] = useState<SubscriptionEntry>( { ...sub.currentEntry } );
    const plan      = sub.currentEntry.plan;
    const unitPrice = sub.currentEntry.price;
    const quantity  = sub.currentEntry.quantity;

    useEffect( () => {
        Api.facilityDetails( sub.facility.facilityId ).then(
            res => processResult( res,
                val => {
                    const f = parseFacility( val );
                    setFacility( f );
                    setOldMonthlyPlan( f.listedPlans.find( lp => lp.planEntryId === plan.planId ) );
                    setSelMonthlyPlan( f.listedPlans.find( lp => lp.planEntryId === plan.planId ) );
                },
                err => console.log( `Problem retrieving facility: ${err}`) ) ); //ignore for now
    }, [] );

    useEffect( () => {
        Api.vehicleList().then(
            res => processResult( res,
                val => setMyVehicles( val ),
                err => console.log( `Didn't retrieve vehicles because: ${err}`) ) ); //ignore for now
    }, [] );

    if( !facility || !selMonthlyPlan || !oldMonthlyPlan ) {
        return <></>;
    }

    return <Container fluid className="pb-3">
        <Row>
            <Col>
                <PageTitle>
                    Modify Plan at {facility.name}
                </PageTitle>
                <div className="text-secondary">
                </div>
                <Container className="g-0">
                    <Row>
                        <Col xl="6">
                            <div className="fs-5 fw-bold">
                                Old Plan
                            </div>
                            <CurrentPlanDetails
                                color="gray"
                                subscription={sub} />
                        </Col>
                        <Col xl="6">
                            <div className="fs-5 fw-bold">
                                New Plan
                            </div>
                            <MonthlyParkingDetails
                                start={LocalDate.now()}
                                listedPlans={facility.listedPlans}
                                selOpt={selMonthlyPlan}
                                setSelOpt={ x => setSelMonthlyPlan( facility.listedPlans.find( p => x.planEntryId == p.planEntryId ) ) }
                                quantity={modSub.quantity}
                                setQuantity={ n => setModSub( { ...modSub, quantity: n } ) } />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-end">
                        <Col xs="12" className="d-flex justify-content-end">
                            <Button>
                                Check Out
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}
