import { Modal             } from "react-bootstrap";
import { Button            } from "./Controls/Button";
import { ExclamationCircle } from "react-bootstrap-icons";
import { HeaderText } from "./Controls/HeaderText";

interface DeletePaymentMethodModalProps {
    show:    boolean;
    setShow: ( show: boolean ) => void;
}

export function DeletePaymentMethodModal( { show, setShow }: DeletePaymentMethodModalProps ) {    
    return <Modal centered show={show} onEscapeKeyDown={ () => setShow( false ) }>
        <Modal.Header>
            <HeaderText>Delete Payment Method</HeaderText>
        </Modal.Header>
        <Modal.Body>
            <div className="alert alert-danger text-danger fw-bold">
                If you continue, you will leave some plans without a payment method
            </div>
            <div className="text-danger-light mb-4">
                Make sure you update your plans to use new payment methods if you don't want them to cancel on the next due date.
            </div>
            <div className="w-100 d-flex flex-row justify-content-end gap-2">
                <Button className="w-50" onClick={() => setShow( false )}>
                    Cancel
                </Button>
                <Button className="w-50 bg-danger text-white border-danger">
                    Delete Card
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
}