import { Elements   } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useEffect, useState } from "react";
import { PageTitle } from "./Controls/PageTitle";
import { Col, Container, Row } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import { Modal, Form } from "react-bootstrap";
import { 
    CreditCard2Front,
    Link45deg
} from 'react-bootstrap-icons';
import { HeaderText } from "./Controls/HeaderText";
import { Button } from "./Controls/Button";
import { Api, SetupIntentCreateResp } from "./Data/Api";
import { stripePromise } from "./Data/ApiStripe";
import { StripeAddPaymentMethodForm } from "./StripeAddPaymentMethodForm";

export interface BillingInfoModalProps {
    show: boolean;
    setShow: (b: boolean) => void;
}

export function BillingInfoModal(props: BillingInfoModalProps) {
    const { show, setShow } = props;

    const billingInfo = [
        { 
            icon: <CreditCard2Front className="fs-4 me-2" />, 
            text: "When you select it during checkout and complete your purchase" 
        },
        { 
            icon: <Link45deg className="fs-4 me-2" />, 
            text: "If you link it with a recurring plan it will be charged it on the next due date"
        }
    ];

    return (
        <Modal show={show} centered>
            <Modal.Header>
                <HeaderText>Payment Method Info</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <p className="">We will only charge this payment method:</p>
                <ul className="ms-3 mb-4 list-unstyled">
                    {billingInfo.map((info, index) => (
                        <div key={index} className="mb-4 d-flex align-items-center">
                            {info.icon} {info.text}
                        </div>
                    ))}
                </ul>
                <Form>
                    <div className="d-flex justify-content-end">
                        <Button className="w-100" type="button" onClick={() => setShow(false)}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

//?setup_intent=seti_1PpfVXEiTb5JNZ8NIsqmYaK1&setup_intent_client_secret=seti_1PpfVXEiTb5JNZ8NIsqmYaK1_secret_Qh3cJ4XD92OywH0yzVXtQCD4jXI9Ll1&redirect_status=succeeded
export function UserBillingAddPage() {
    const [ showHelp, setShowHelp ] = useState( false );
    const [err, setErr] = useState<string>();
    const [ createResp, setCreateResp ] = useState<SetupIntentCreateResp>();
    const [ paymentVis, setPaymentVis ] = useState<"hidden" | "visible">( "hidden" );
    useEffect( () => {
        //fetch SetupIntent client secret from the server
        Api.stripeSetupIntentCreate()
           .then( res => {
                if( !res.isOk ) {
                    setErr( res.error );
                    return;
                }
                setCreateResp( res.value );
                setTimeout( () => setPaymentVis( "visible" ), 500 );
        } );
    }, [] );

    const options: StripeElementsOptions = {
        clientSecret: createResp?.clientSecret,
        appearance: { 
            theme: "stripe", 
            disableAnimations: true, 
            labels: "above",
            variables: {
                fontFamily: "sans-serif",
            }
        },
    };
    
    return <Container>
        <BillingInfoModal show={showHelp} setShow={setShowHelp} />
        <Row>
            <Col>
                <PageTitle>
                    Add Payment Method
                </PageTitle>                
                <div className="d-flex align-items-center gap-2 my-2 mb-3">
                    When will I be charged? <InfoCircleFill className="fs-6" color="violet" cursor="pointer" onClick={ () => setShowHelp( true ) } />
                </div>
                { paymentVis === "hidden" && <div className="text-secondary">
                    Loading...
                </div>}
                {err && <div className="alert alert-danger">
                    {err}
                </div>}
                <div style={{ visibility: paymentVis }}>
                    {createResp && <Elements stripe={stripePromise} options={options}>
                        <StripeAddPaymentMethodForm />
                    </Elements>}
                </div>
            </Col>
        </Row>
    </Container>
};