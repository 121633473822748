import { Duration, LocalDateTime, LocalTime } from "js-joda";
import { useParams } from "react-router-dom";
import { Money } from "./Money";
import { ApiUrl } from "./ApiUrl";
import { parseVehicle } from "./ApiParse";
import { Facility, FacilityImage, Operator, Vehicle } from "./ApiTypes";

export enum VehicleColor {
    White  = "White",
    Black  = "Black",
    Gray   = "Gray",
    Silver = "Silver",
    Red    = "Red",
    Green  = "Green",
    Blue   = "Blue",
    Brown  = "Brown",
    Orange = "Orange",
  //Beige  = "Beige",
    Purple = "Purple",
    Gold   = "Gold",
    Yellow = "Yellow"
}

export const colors = [
    VehicleColor.White,
    VehicleColor.Black,
    VehicleColor.Gray,
    VehicleColor.Silver,
    VehicleColor.Red,
    VehicleColor.Green,
    VehicleColor.Blue,
    VehicleColor.Brown,
    VehicleColor.Orange,
  //VehicleColor.Beige,
    VehicleColor.Purple,
    VehicleColor.Gold,
    VehicleColor.Yellow
];

export function useParamIds() {
    const pars = useParams();
    const result = Object.fromEntries( Object.entries( pars ).map( ( [key, value] ) => {
        return [key, parseInt( value! )]; // Or any transformation you want
    } ) );
    return result;
}

export function useOptionalParamIds() {
    const pars = useParams();
    const result = Object.fromEntries( Object.entries( pars ).map( ( [key, value] ) => {
        return [key, value ? parseInt( value ) : undefined]; // Or any transformation you want
    } ) );
    return result;
}

//in our types, parent entities do not hold a reference to child entities generally
export enum FacilityType {
    Lot         = 'Lot',
    Garage      = 'Garage',
    ValetGarage = 'Valet Garage',
}

type IsActive = ( d: Duration, a: LocalDateTime, b: LocalDateTime ) => boolean;
type Charge   = ( d: Duration, a: LocalDateTime, b: LocalDateTime ) => Money;

export enum ResetType {
    MaxDuration,    //restart the rate program after a certain duration
    MaxCharge,      //restart the rate program once a certain fee has been reached
    MaxTimeOfDay    //restart the rate program at a certain minute of the day
}

export interface DurationReset  { type: ResetType.MaxDuration;  duration:  Duration;  }
export interface ChargeReset    { type: ResetType.MaxCharge;    amount:    Money;     }
export interface TimeOfDayReset { type: ResetType.MaxTimeOfDay; timeOfDay: LocalTime; }
export type Reset = DurationReset | ChargeReset | TimeOfDayReset;

export interface ParkingRateFixed { unit:     Duration,                 }
export interface ParkingRateFx    { isActive: IsActive; charge: Charge; }
export interface ParkingRateUpTo  { upTo:     Duration; rate:   Money;  }

export const facilityImg = ( imgId: number | null ) => {
    if( imgId == null ) {
        return "/img/pier-v-small.png"; //TODO: replace with placeholder image
    }
    return ApiUrl.imageGet( imgId );
};

const sleep = (ms: number): Promise<void> =>
    new Promise( resolve => setTimeout( resolve, ms ) );