
export interface OkResult<TVal> {
    isOk:  true;
    value: TVal;
}

export interface ErrResult<TErr> {
    isOk:  false;
    error: TErr;
}

export type Result<TErr, TVal> = OkResult<TVal> | ErrResult<TErr>;

export type OkHandler<T>  = ( val: T ) => void;
export type ErrHandler<T> = ( val: T ) => void;

function defaultErrHandler<T>( err: T ) {
    alert( err );
    console.error( err );
}

//for loaders
export type OkLoader<TIn, TOut>  = ( val: TIn ) => TOut;
export type ErrLoader<TIn, TOut> = ( val: TIn ) => TOut;

export function process<TErr, TVal>( promise : Promise<Result<TErr, TVal>>,
                                     okFunc: OkHandler<TVal>,
                                     errFunc: ErrHandler<TErr> = defaultErrHandler ): void {
    promise.then( res => {
        processResult( res, okFunc, errFunc );
    } );
}

export function processResult<TErr, TVal>( res:     Result<TErr, TVal>,
                                           okFunc:  OkHandler<TVal>,
                                           errFunc: ErrHandler<TErr> = defaultErrHandler ): boolean {
    if( res.isOk ) {
        okFunc( res.value );
        return true;
    }
    errFunc( res.error );
    return false;
}

export function loadResult<TErr, TVal, TOkOut, TErrOut>( res:     Result<TErr, TVal>,
                                                         okFunc:  OkLoader<TVal, TOkOut>,
                                                         errFunc: ErrLoader<TErr, TErrOut> ) {
    if( res.isOk ) {
        return okFunc( res.value );
    }
    throw errFunc( res.error ); //TODO: I'm not sure this is what I should do... :/
}