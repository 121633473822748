import { Card, Col, Container, Row } from "react-bootstrap";
import { showMoney  } from "./Data/Money";
import { showAddressHtml } from "./Data/Location";
import { useLoaderData, useNavigate     } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { facilityImg } from "./Data/Common";
import { Subscription } from "./Data/ApiTypes";
import { multiply } from "dinero.js";
import { pluralize } from "./Data/English";

export function UserPlansPage() {
    const nav = useNavigate();
    const subs = useLoaderData() as Subscription[];
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Plans
                </PageTitle>
                {subs.length === 0 && <>
                    You don't have any parking plans
                </>}
                {subs.map( sub => {
                    const facility     = sub.facility;
                    const plan         = sub.currentEntry.plan;
                    const subscription = sub.currentEntry;
                    const quantity     = subscription.quantity;
                    const price        = multiply( sub.currentEntry.price, sub.currentEntry.quantity );
                    return <Card key={sub.subscriptionId} className="my-2" style={{ cursor: "pointer" }}
                                 onClick={() => nav( `/user/plan/${sub.subscriptionId}` )}>
                        <Card.Header className="text-start">
                            Monthly Plan at {facility.name}
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                <img className="facility-result-pic"
                                    src={facilityImg( facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div className="grid-area-b">
                                <div className="fw-bold">
                                    {quantity} {pluralize( quantity, "Spot", "Spots" )}
                                </div>
                                {showAddressHtml( facility.address )}
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fs-4 fw-bold">
                                    {showMoney( price )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    per month
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </Container>;
}