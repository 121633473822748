import { Appearance, CustomFontSource } from "@stripe/stripe-js"

const silkaRegular: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-regular-webfont.woff) format('woff')",
    weight: "normal"
}

const silkaBold: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-bold-webfont.woff) format('woff')",
    weight: "bold"
}

export const appearanceOptions: Appearance = {
    theme: "stripe",
    disableAnimations: true,
    labels: "above",
    variables: {
        focusBoxShadow:                 "0 0 0 0.25rem rgba(214, 31, 132, 0.25)",
        fontFamily:                     "silka",
        fontSizeBase:                   "16px",
        // spacingUnit:                    "6px",
        borderRadius:                   "16px",
        colorPrimary:                   "#D61F84", //optional appearance param,
        fontWeightNormal:               "normal",
        fontWeightBold:                 "normal",
        fontWeightMedium:               "normal",
        // buttonPrimaryColorText:         "#000000",
        // buttonPrimaryColorBackground:   "#FFFFFF",
        // buttonPrimaryColorBorder:       "#D61F84",
        // buttonSecondaryColorBackground: "#FFFFFF",
        // buttonSecondaryColorBorder:     "#D61F84",
        // buttonSecondaryColorText:       "#000000",
    },
};

export const stripeFonts = [silkaRegular, silkaBold];