import { Card } from "react-bootstrap";
import { Button } from "./Button";
import { getStateEnumFromCode, showStateName } from "../Data/UsState";
import { Vehicle } from "../Data/ApiTypes";

export interface VehicleCardProps {
    vehicle:       Vehicle;
    vehicles:      Vehicle[];
    deleteVehicle: ( vehicle: Vehicle ) => void;
    disabled?:     boolean;
}

export function VehicleCard( { vehicle, deleteVehicle, vehicles, disabled = false }: VehicleCardProps ) {
    return <Card className="mb-2">
        <Card.Body className="gap-1"
            style={{
                display: "grid",
                alignItems: "center", /* color make model */
                gridTemplateColumns: "min-content 1fr auto"
            }}>
            <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: vehicle.color }} />  
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {vehicle.make} {vehicle.model}
                </div>
                <div>
                    {showStateName( getStateEnumFromCode( vehicle.stateCode )! )} {vehicle.licensePlateNumber}
                </div>
            </div>
            {!disabled && <div className="w-100">
                <Button onClick={() => deleteVehicle( vehicle )}>Remove</Button>
            </div>}
        </Card.Body>
    </Card>;
}
