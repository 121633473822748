export interface OperatorApi {
    operatorId: number;
    name: string;
    address: UsAddressApi;
    emailAddress: string;
    phoneNumber: string;
    website: string;
    topTitle: string;
    topText: string;
    bottomLeftTitle: string;
    bottomLeftText: string;
    bottomRightTitle: string;
    bottomRightText: string;
}

export interface FacilityInfoApi {
    facilityId: number;
    operatorId: number;
    name: string;
    description: string;
    timeZone: string;
    address: UsAddressApi;
    coordinates: CoordinatesApi;
    listImageId: number | null;
    entryInstructions: string;
}

export interface UsAddressApi {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface CoordinatesApi {
    lat: number;
    lng: number;
}

export interface VehicleApi {
    vehicleId: number;
    make: string;
    model: string;
    color: string;
    stateCode: string;
    licensePlateNumber: string;
}

export interface RateProgramApi {
    rateProgramId: number;
    facilityId: number;
    name: string;
    start: string;
    end: string | null;
    inAfter: string | null;
    inBefore: string | null;
    outAfter: string | null;
    outBefore: string | null;
    entries: RateEntryApi[];
}

export interface RateEntryApi {
    rateEntryId: number;
    rateProgramId: number;
    upTo: number;
    rate: number;
}

export interface FacilityImageApi {
    facilityImageId: number;
    storedImageId: number;
    orderId: number;
    caption: string;
    altText: string;
}

export interface ListedPlanApi {
    planId: number;
    planEntryId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    price: number;
}

export interface OrderDetailsApi {
    order: OrderApi;
    balanceChanges: OrderBalanceApi[];
    summary: string;
}

export interface OrderApi {
    orderId: number;
    initiator: OrderInitiator;
    initiatedByStaffId: number | null;
    parkerId: number;
    facilityId: number;
    price: number;
    createdWhen: string;
    processorPaymentIntentKey: string | null;
    type: OrderType;
    facility: FacilityInfoApi;
    submittedWhen: string | null;
    balance: OrderBalanceApi;
    summary: string;
}

export interface OrderBalanceApi {
    orderBalanceId: number;
    type: OrderBalanceType;
    orderId: number;
    createdWhen: string;
    debt: number;
    paid: number;
    balance: number;
    totalDebt: number;
    totalPaid: number;
    reason: string | null;
    paymentId: number | null;
}

export interface InvoiceApi {
    invoiceId: number;
    orderId: number;
    createdWhen: string;
    dueWhen: string;
    openingBalance: number;
    endingBalance: number;
    order: OrderInfoApi;
    facility: FacilityInfoApi;
}

export interface PaymentApi {
    paymentId: number;
    parentPaymentId: number | null;
    facilityId: number;
    facility: FacilityInfoApi;
    parkerId: number;
    type: PaymentType;
    amount: number;
    balance: number;
    remaining: number;
    paymentMethod: string;
    changes: PaymentChangeApi[];
    applications: ApplyPaymentApi[];
    createdWhen: string;
}

export interface ApplyPaymentApi {
    orderBalanceId: number;
    type: OrderBalanceType;
    facilityId: number;
    parkerId: number;
    orderId: number;
    debt: number;
    paid: number;
    balance: number;
    totalDebt: number;
    totalPaid: number;
}

export interface OrderInfoApi {
    orderId: number;
    initiator: OrderInitiator;
    initiatedByStaffId: number | null;
    parkerId: number;
    facilityId: number;
    price: number;
    createdWhen: string;
    processorPaymentIntentKey: string | null;
    orderType: OrderType;
    submittedWhen: string | null;
    balance: OrderBalanceApi;
    summary: string;
}

export interface PaymentChangeApi {
    paymentId: number;
    parentPaymentId: number | null;
    facilityId: number;
    parkerId: number;
    type: PaymentType;
    amount: number;
    balance: number;
    createdWhen: string;
}

export interface OrderDebtApi {
    orderId: number;
    createdWhen: string;
    orderTotal: number;
    paid: number;
    unpaid: number;
}

export interface SignUpReqApi {
    name: string;
    emailAddress: string;
    phoneNumber: string;
    password: string;
    passwordConfirm: string;
}

export interface LogInReqApi {
    emailAddress: string;
    password: string;
}

export interface ChangeEmailReqApi {
    currentPassword: string;
    newEmailAddress: string;
}

export interface ChangePasswordReqApi {
    currentPassword: string;
    newPassword: string;
}

export interface ForgotPasswordReqApi {
    emailAddress: string;
}

export interface ResetPasswordReqApi {
    resetPasswordCode: string;
    newPassword: string;
}

export interface ResetCodeCheckReqApi {
    resetPasswordCode: string;
}

export interface ParkerApi {
    parkerId: number;
    operatorId: number;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    createdWhen: string;
}

export interface FacilityApi {
    facilityId: number;
    name: string;
    description: string;
    timeZone: string;
    address: UsAddressApi;
    coordinates: CoordinatesApi;
    listImageId: number | null;
    entryInstructions: string;
    physicalCapacity: number;
    enforcedCapacity: number;
    images: FacilityImageApi[];
    hourlyRates: RateProgramApi[];
    listedPlans: ListedPlanApi[];
}

export interface SubscriptionApi {
    subscriptionId: number;
    start: string;
    currentEntry: SubscriptionEntryApi;
    history: SubscriptionEntryApi[];
    facility: FacilityInfoApi;
    accessPeriods: AccessPeriodInfoApi[];
}

export interface SubscriptionEntryApi {
    subscriptionEntryId: number;
    subscriptionId: number;
    planEntryId: number;
    planPriceId: number;
    quantity: number;
    start: string;
    end: string | null;
    isAccountsReceivable: boolean;
    accessPeriods: AccessPeriodInfoApi[];
    price: number;
    plan: PlanInfoApi;
}

export interface PlanInfoApi {
    planId: number;
    facilityId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
}

export interface ReservationApi {
    reservationId: number;
    facilityId: number;
    parkerId: number;
    start: string;
    end: string;
    actualStart: string;
    actualEnd: string;
    duration: number;
    rateProgramId: number;
    price: number;
    createdWhen: string;
    facility: FacilityInfoApi;
    rateProgram: RateProgramApi;
}

export interface ReserveInitReqApi {
    facilityId: number;
    start: string;
    end: string;
}

export interface ReserveInitRespApi {
    facilityId: number;
    start: string;
    end: string;
    stayDuration: number;
    chargeDuration: number;
    orderId: number;
    price: number;
    customerSessionClientSecret: string;
    paymentIntentClientSecret: string;
}

export interface ReserveConfirmReqApi {
    orderId: number;
}

export interface ReserveConfirmRespApi {
    statusMsg: string;
}

export interface SubscribeInitReqApi {
    facilityId: number;
    planEntryId: number;
    start: string;
    quantity: number;
}

export interface SubscribeInitRespApi {
    facilityId: number;
    planEntryId: number;
    start: string;
    quantity: number;
    price: number;
    orderId: number;
    paymentIntentClientSecret: string;
    customerSessionClientSecret: string;
}

export interface SubscribeConfirmReqApi {
    orderId: number;
}

export interface SubscribeConfirmRespApi {
    status: string;
}

export interface OrderPayInitReqApi {
    orderId: number;
}

export interface OrderPayInitRespApi {
    orderId: number;
    price: number;
    paymentIntentClientSecret: string;
    customerSessionClientSecret: string;
}

export interface SettleInitReqApi {
    facilityId: number;
}

export interface SettleInitRespApi {
    facility: FacilityInfoApi;
    orders: OrderApi[];
    price: number;
    paymentIntentClientSecret: string;
    customerSessionClientSecret: string;
}

export interface FacilityParkerBalanceApi {
    facility: FacilityInfoApi;
    balance: number;
    orderDebts: OrderDebtApi[];
}

export interface AccessPeriodInfoApi {
    accessPeriodId: number;
    subscriptionEntryId: number;
    start: string;
    end: string;
    isDelivered: boolean;
    price: number;
}


export enum OrderType {
    Imported = 5,
    Reserve = 10,
    Subscribe = 20,
    ModifySubscription = 30,
    AccessPeriod = 40,
    Custom = 50,
}

export enum PaymentType {
    ImportedPayment = 20,
    CashPayment = 40,
    AchPayment = 60,
    AchFailed = 80,
    CheckPayment = 100,
    CheckBounce = 120,
    WriteCheck = 140,
    ProcessorPayment = 160,
    ProcessorRefund = 180,
    ProcessorDisputeChange = 200,
    Adjustment = 220,
}

export enum OrderInitiator {
    System = 0,
    Parker = 1,
    Staff = 2,
}

export enum OrderBalanceType {
    OrderPlaced = 10,
    OrderFee = 20,
    ApplyPayment = 30,
    UndoApplyPayment = 40,
    RefundPayment = 50,
    OrderAdjustment = 60,
}


