import { Modal, Form } from "react-bootstrap";
import { Input } from "./Input";
import { Controller, useForm } from "react-hook-form";
import { Button } from "./Button";
import { HeaderText } from "./HeaderText";
import { colors } from "../Data/Common";
import { Vehicle } from "../Data/ApiTypes";
import { getStateEnumFromCode } from "../Data/UsState";

export interface AddVehicleModalProps {
    show:     boolean;
    setShow:  ( b: boolean ) => void;
    onSubmit: ( v: Vehicle ) => void;
}

export function AddVehicleModal( props: AddVehicleModalProps ) {
    const { show, setShow, onSubmit } = { ...props };
    const { handleSubmit, register, getValues, reset, control, formState: { errors } }
        = useForm<Vehicle>( { defaultValues: { licensePlateNumber: "", stateCode: "" } } );

    function submit() {
        const vals = getValues();
        onSubmit( vals );
        reset( { make: "", model: "", licensePlateNumber: "", stateCode: "", color: undefined } );
        setShow( false );
    }

    return <Modal show={props.show} centered>
            <Modal.Header>
                <HeaderText>Add Vehicle</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit( submit )}>
                    <div className="d-flex flex-row w-100 gap-3 mb-3">
                        <Input className="w-100"
                            type="text"
                            label="Make"
                            formNoValidate
                            {...register( "make", { required: "Make is required" } )}
                            error={errors.make} />
                        <Input className="w-100"
                            type="text"
                            label="Model"
                            formNoValidate
                            {...register( "model", { required: "Model is required" } )}
                            error={errors.model} />
                    </div>
                    <div>
                        <Input
                            type="text"
                            label="2-Letter State Code"
                            formNoValidate
                            {...register( "stateCode", {
                                required: "2-Letter State Code is required",
                                validate: ( value ) => {
                                    if( !value )
                                        return true;
                                    const stateEnum = getStateEnumFromCode( value );
                                    return stateEnum !== undefined || "Invalid state code";
                                },
                            } )}
                            error={errors.stateCode}
                            maxLength={2} />
                    </div>
                    <div>
                        <Input
                            type="text"
                            label="License Plate Number"
                            formNoValidate
                            {...register( "licensePlateNumber", { required: "License Plate Number is required" } )}
                            maxLength={10}
                            error={errors.licensePlateNumber} />
                    </div>
                    <div className="mb-3">
                        Vehicle Color
                        <Controller
                            control={control}
                            name="color"
                            rules={{ required: "Vehicle Color is required" }}
                            render={( { field: { onChange, onBlur, value, ref } } ) => {
                                return <div className="mb-2" style={{  width: "100%",
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(6, 1fr)',
                                    justifyItems: "center",
                                    gap: '0.20em' }}>
                                    {colors.map( col => {
                                        const sel = value === col ? "border border-primary-subtle border-4 shadow" : "";
                                        return <div key={col} className={`${sel} m-1 border d-inline-block`}
                                            style={{ height: 48, width: 48, backgroundColor: col }}
                                            onClick={ () => onChange( col ) }>
                                        </div>;
                                    } )}
                                </div>;
                            } } />
                    </div>
                    <div className="mb-3">
                        {errors.color && <Form.Text className="d-block text-danger">
                            {errors.color.message}
                        </Form.Text>}
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <Button type="button" className="w-100" onClick={() => setShow( false )}>
                            Cancel
                        </Button>
                        <Button type="submit" className="w-100">
                            Add Vehicle
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
}