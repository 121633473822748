export interface AddSubtractProps {
    quantity: number;
    set: ( n: number ) => void;
}

function safeAdd( n: number, delta: number ) {
    return Math.max( 1, n + delta );
}

export function AddSubtract( props: AddSubtractProps ) {
    const { quantity, set } = props;    
    return <div style={{ fontFamily: "monospace" }} className="d-flex align-items-center">
        <span className="user-select-none px-2 border border-black border-2 text-white bg-primary rounded-start-4 border-end-0 cursor-pointer"
              onClick={ () => set( safeAdd( quantity, -1 ) ) }>
            -
        </span>
        <span className="user-select-none px-2 border border-black border-top border-end-0 border-2 text-white bg-primary">
            {quantity}
        </span>
        <span className="user-select-none px-2 border border-black border-2 text-white bg-primary rounded-end-4 cursor-pointer"
            onClick={ () => set( safeAdd( quantity, 1 ) ) }>
            +
        </span>
    </div>;
}