import { DateTime        } from "luxon";
import { atomWithStorage } from "jotai/utils"
import { LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from "js-joda";
import { atom } from "jotai";
import { Facility, Parker, Vehicle, ListedPlan } from "./ApiTypes";
import { nearest30, showBrowserDate, showBrowserTime } from "./Date";

export enum SearchActivity {
    Hourly  = "hourly",
    Monthly = "monthly"
}

export interface HourlyForm {
    location:       string;
    enterAfterDate: string;
    enterAfterTime: string;
    exitBeforeDate: string;
    exitBeforeTime: string;
}

export interface MonthlyForm {
    location:  string;
    startDate: string;
}

export type SearchActivityType<T> = T extends SearchActivity.Hourly ? HourlyForm : MonthlyForm;

export const createHourlyForm = (): HourlyForm => {
    const now = LocalDateTime.now();
    const oneHourLater = now.plusHours( 3 );
    const ret = {
        location:       "",
        enterAfterDate: showBrowserDate( now.toLocalDate() ),
        enterAfterTime: showBrowserTime( nearest30( now ).toLocalTime() ),
        exitBeforeDate: showBrowserDate( oneHourLater.toLocalDate() ),
        exitBeforeTime: showBrowserTime( nearest30( oneHourLater ).toLocalTime() )
    };
    return ret;
};

export const createMonthlyForm = (): MonthlyForm => ( {
    location:  "",
    startDate: DateTime.now().toFormat( "yyyy-MM-dd" ),
} );

export interface HourlyCheckout {
    facility: Facility;
    start:    LocalDateTime;
    end:      LocalDateTime;
    vehicles: Vehicle[];
}

export interface MonthlyCheckout {
    facility: Facility;
    option:   ListedPlan;
    start:    LocalDate;
    quantity: number;
    vehicles: Vehicle[];
}

export interface SpotSyncAuth {
    isLoggedIn:  boolean;
    account?:    Parker;
}

export interface PageMaskOpts {
    showMask: boolean;
    header:   React.ReactNode;
    subText?: string;
}

// function getDefaultRes<TVal>(): Result<string, TVal> {
//     return { isOk: false, error: "loading" };
// }

// const createFetchAtom = <T>( url: string, defaultValue: T ) => {
//     const baseAtom = atom<Result<string, T>>( getDefaultRes<T>() );
//     const derivedAtom = atom(
//         async ( get ) => {
//             const value = get( baseAtom );
//             if( value.isOk === false )
//                 return value;

//             try {
//                 const response = await fetch( url, {  credentials: "include" } );
//                 if( !response.ok )
//                     throw new Error( 'Network response was not ok' );
//                 const data = await response.json() as Result<string, T>;
//                 return data;
//             }
//             catch( error ) {
//                 console.error( 'Error fetching data:', error );
//                 return defaultValue;
//             }
//         },
//         ( get, set, newValue: T ) => {
//             set( baseAtom, { isOk: true, value: newValue } );
//         }
//     );
//     const refreshAtom = atom( null, ( get, set ) => {
//         set( baseAtom, { isOk: false, error: "loading" } ); // Clear the current value
//     } );
//     return [derivedAtom, refreshAtom] as const;
// };

export const tabAtom             = atomWithStorage<SearchActivity>( "tab", SearchActivity.Hourly, undefined, { getOnInit: true } );
export const hourlyFormAtom      = atom<HourlyForm>(  createHourlyForm()  );
export const monthlyFormAtom     = atom<MonthlyForm>( createMonthlyForm() );
export const authAtom            = atomWithStorage<SpotSyncAuth>( "auth", { isLoggedIn: false }, undefined,  { getOnInit: true } );
export const hourlyCheckoutAtom  = atom<HourlyCheckout  | undefined>( undefined );
export const monthlyCheckoutAtom = atom<MonthlyCheckout | undefined>( undefined );
export const redirectAtom        = atomWithStorage<string | undefined>('redirectPath', undefined, undefined, { getOnInit: true } );
export const btnLoadingAtom      = atom(false);
export const maskAtom            = atom<PageMaskOpts | undefined>(undefined);
// export const [reservations, refreshReservations] = createFetchAtom<ReservationApi[]>( ApiUrl.reservationList(), [] );