import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from "./Controls/Button";
import { connectUrl } from "./Data/WebUrl";

export function StripeAddPaymentMethodForm() {
    const stripe   = useStripe();
    const elements = useElements();

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();

        if( !stripe || !elements ) {
            return;
        }

        const result = await stripe.confirmSetup( {
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: connectUrl( "/user/billing/add/success" ),
            },
        } );        

        if( result.error ) {
            // Show error to your customer (for example, payment details incomplete)
            console.log( result.error.message );
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="d-flex justify-content-end">
            <Button type="submit" disabled={!stripe} className="mt-2 px-3">
                Add Payment Method
            </Button>
        </div>
    </form>;
};

