import { forwardRef, ReactNode } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { FieldError, UseFormStateReturn } from "react-hook-form";

type InputType = "text" | "date" | "time" | "month" | "password" | "email" | "tel";

export interface InputProps extends FormControlProps {
    type:          InputType;
    className?:    string;
    label:         string;
    icon?:         ReactNode;
    placeholder?:  string;
    explanation?:  string;
    error?:        FieldError | undefined;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export const Input = forwardRef<FormControlElement, InputProps>(
    ( { type,
        className = "mb-3",
        label,
        icon,
        error,
        placeholder = "",
        explanation = "",
        ...props
    }, ref ) => {
        return <Form.Group className={className}>
            <Form.Label className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>
                    {icon}
                </InputGroup.Text>}
                <Form.Control
                    {...props}
                    ref={ref}
                    placeholder={placeholder}
                    type={type} />
            </InputGroup>
            {explanation &&
                <Form.Text className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);