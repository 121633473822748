import React from "react";

export interface HeaderTextProps {
    size?:      number;
    className?: string;
    children:   React.ReactNode;
}

export function HeaderText( { size = 4, children, className = "" }: HeaderTextProps ) {
    return <div className={`${className} fs-${size} fw-bold`}>
        {children}
    </div>;
}
