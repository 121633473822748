import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Payment } from "./Data/ApiTypes";
import { PageTitle } from "./Controls/PageTitle";
import { cents, showMoney } from "./Data/Money";
import { showPaymentType, showPaymentTypeAction } from "./Data/Finance";
import { showInstant } from "./Data/Date";
import { equal } from "dinero.js";
import { Hyperlink } from "./Controls/Hyperlink";

export function UserPaymentDetailsPage() {
    const nav = useNavigate();
    const payment = useLoaderData() as Payment;
    const facility = payment.facility;
    const changes = payment.changes;

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Payment #{payment.paymentId}
                </PageTitle>
                <Container className="g-0">
                    <Row>
                        <Col xs={12} className="fs-4">
                            {facility.name}
                        </Col>
                        <Col className="fs-6">
                            <table className="table w-auto">
                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td className="text-end" colSpan={2}>{showInstant( payment.createdWhen, facility.timeZone )}</td>
                                    </tr>
                                    <tr>
                                        <td>Method</td>
                                        <td className="text-end" colSpan={2}>{payment.paymentMethod}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td className="fw-bold text-end">{showMoney( payment.amount )}</td>
                                    </tr>
                                    {changes.length != 0 && <tr className="fw-bold">
                                        <td>Remaining</td>
                                        <td className="text-end">{showMoney( payment.balance )}</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
                <PageTitle>
                    Payment History
                </PageTitle>
                <Card key={payment.paymentId} className="my-2">
                    <Card.Header className="text-start fw-bold" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <div>
                            {showPaymentType( payment.type )}
                        </div>
                        <div className="text-end">
                            {showInstant( payment.createdWhen, facility.timeZone )}
                        </div>
                    </Card.Header>
                    <Card.Body className="purchase-layout gap-2">
                        <div className="grid-area-a">
                            <div>
                                {!equal( cents( 0 ), payment.amount ) && <>
                                    Paid {showMoney( payment.amount )}
                                    <ul className="list-group list-group-flush">
                                        {payment.applications.map( app => <li className="list-group-item">
                                            {showMoney( app.paid )} for <Hyperlink to={`/user/order/${app.orderId}`}>
                                                Order #{app.orderId}
                                            </Hyperlink>
                                        </li> ) }
                                    </ul>
                                </>
                            } </div>
                        </div>
                        <div className="grid-area-b fw-bold">

                        </div>
                        <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                            <div className="fw-bold text-end">
                                {/* TODO: HELP! */}
                                {showMoney( payment.amount )}
                            </div>
                            <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                Funds Remaining
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {changes.map( change => {
                    return <Card key={change.paymentId} className="my-2">
                        <Card.Header className="text-start fw-bold" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div>
                                {showPaymentTypeAction( change.type )}
                            </div>
                            <div className="text-end">
                                {showInstant( change.createdWhen, facility.timeZone )}
                            </div>
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                {!equal( cents( 0 ), change.amount ) && <>
                                    {showMoney( change.amount )}
                                </>}
                            </div>
                            <div className="grid-area-b fw-bold">
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fw-bold text-end">
                                    {showMoney( change.balance )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    Funds Remaining
                                </div>
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
                {/* <PageTitle>
                    Orders Paid
                </PageTitle> */}
            </Col>
        </Row>
    </Container>;
}