import { loadStripe } from '@stripe/stripe-js';
import { PageTitle } from "./Controls/PageTitle";
import { Col, Container, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

//must be outside of a component
//do we need this in more of a central place?
//can it be safely called more than once I wonder?
const stripePromise = loadStripe( 'pk_test_51PiFMOEiTb5JNZ8Ne5QdmqoLgXLjJIsb67H9AImdn2QNC34jbLfOlueQn8UGWALow5RtKpt7YDObcHqrx3Ty8GwA00L0sIQNaS' );

export function UserBillingAddSuccessPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [ setupIntent, setupIntentClientSecret, redirectStatus ] = [
        searchParams.get( "setup_intent" ),
        searchParams.get( "setup_intent_client_secret" ),
        searchParams.get( "redirect_status" )
    ];
    console.log( setupIntent             );
    console.log( setupIntentClientSecret );
    console.log( redirectStatus          );
    return <Container>
        <Row>
            <Col>
                <PageTitle>
                    Add Payment Method
                </PageTitle>
                { redirectStatus === "succeeded" && <>
                    Success!
                </>}
            </Col>
        </Row>
    </Container>
};