//third party
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Alert, Card } from "react-bootstrap";
import { Asterisk,     At }    from "react-bootstrap-icons";

//spotsync
import { Button                                  }  from "./Controls/Button";
import { authAtom, redirectAtom, btnLoadingAtom  }  from "./Data/Atoms";
import { useState                                }  from "react";
import { HeaderText                              }  from "./Controls/HeaderText";
import { CenteredPage                            }  from "./Controls/CenteredPage";
import { Input                                   }  from "./Controls/Input";
import { Api                                     }  from "./Data/Api";
import { parseParker } from "./Data/ApiParse";

export const LogInPage = () => {

    const [auth,   setAuth] = useAtom( authAtom );
    const [errMsg, setErrMsg] = useState<string>();
    const [redirect, setRedirect] = useAtom( redirectAtom );
    const [isLoading, setIsLoading] = useState(false);

    const nav = useNavigate();

    interface LogInForm {
        emailAddress: string;
        password:     string;
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LogInForm>();


    function submit( data: LogInForm ) {
        setIsLoading( true );
        Api.logIn( { ...data } ).then(
            res => {
                if( res.isOk ) {
                    const parker = parseParker( res.value );
                    setAuth( { isLoggedIn: true, account: parker } );
                    if( redirect !== undefined ) {
                        setRedirect( undefined );
                        nav( `${redirect}` );
                    }
                    nav( "/" );
                    return;
                }
                setErrMsg( res.error );
            }
        ).finally(() => {
            setIsLoading( false );
        } );
    }

    return <CenteredPage sm="10" md="8" lg="6" xl="4" style={{ maxWidth: "30em" }}>
        <Card className="w-100">
            <form onSubmit={handleSubmit( submit )}>
                <Card.Header>
                    <HeaderText>Log In</HeaderText>
                </Card.Header>
                <Card.Body className="py-4">
                    <Input
                        type="email"
                        label="Email Address"
                        icon={<At className="fs-5" />}
                        placeholder="Enter your email"
                        error={errors.emailAddress}
                        {...register( "emailAddress", { required: "Email Address is required" } )}
                    />
                    <Input
                        type="password"
                        label="Password"
                        icon={<Asterisk className="fs-6" />}
                        placeholder="Enter your password"
                        error={errors.password}
                        {...register( "password", { required: "Password is required" } )}
                    />
                    {errMsg && (
                        <Alert variant="danger" className="text-danger">
                            {errMsg}
                        </Alert>
                    )}
                    <div className="mb-3">
                        <Link to="/signup">Sign up</Link>
                    </div>
                    <div>
                        <Link to="/forgot">Forgot Password</Link>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex flex-row justify-content-end gap-2">
                        <Button className="w-100" onClick={() => nav( "/" )}>
                            Back
                        </Button>
                        <Button type="submit"
                                loading={isLoading}
                                loadingText="Loading"
                                className="w-100">
                            Log In
                        </Button>
                    </div>
                </Card.Footer>
            </form>
        </Card>
    </CenteredPage>;
};
