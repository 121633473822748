import { ReactNode } from "react";
import { showFacilityAddress } from "../Data/Location";
import { UsAddress } from "../Data/ApiTypes";

export interface MapAddressProps {
    address:  UsAddress;
    children: ReactNode;
}

export function MapAddress( { address, children = <></> } : MapAddressProps ) {    
    const addr = showFacilityAddress( address );    
    return <a href={`http://maps.apple.com/?q=${encodeURIComponent( addr )}`}>
        {children}
    </a>;    
}