import { Modal } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { HeaderText } from "./Controls/HeaderText";

interface DeleteMyAccountForm {
    emailAddress: string;
    password:     string;
}

interface UserPageDeleteAccountModalProps {
    show:    boolean;
    setShow: ( show: boolean ) => void;
}

export function UserPageDeleteAccountModal( { show, setShow }: UserPageDeleteAccountModalProps ) {
    //not implemented yet    
    return <Modal centered show={show} onEscapeKeyDown={ () => setShow( false ) }>
        <Modal.Header>
            <HeaderText>Are you sure you want to do this?</HeaderText>
        </Modal.Header>
        <Modal.Body>
            <div className="alert alert-danger d-flex align-items-center gap-2 justify-content-center">
                <ExclamationCircle /> This will delete your account!
            </div>
            <div className="text-danger-light mb-4">
                This action cannot be undone.
            </div>
            <div className="text-secondary-light mb-4">
                To continue, enter your email and password and click 'Delete Account'.
            </div>
            <Input label="Email Address" type="text"     autoComplete="one-time-code" />
            <Input label="Password"      type="password" autoComplete="one-time-code" />
            <div className="w-100 d-flex flex-row justify-content-end gap-2">
                <Button className="w-50" onClick={() => setShow( false )}>
                    Cancel
                </Button>
                <Button className="w-50 btn-danger border-danger">
                    Delete Account
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
}