import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface HyperlinkProps {
    children: ReactNode;
    to?:      string;
    onClick?: ( evt: React.MouseEvent<HTMLSpanElement, MouseEvent> ) => void;
}
export function Hyperlink( { children, to, onClick }: HyperlinkProps ) {
    const nav = useNavigate();

    function internalOnClick( evt: React.MouseEvent<HTMLSpanElement, MouseEvent> ): void {
        if( to !== undefined ) {
            nav( to );
            return;
        }
        if( onClick !== undefined ) {
            onClick( evt );
        }
    }

    return <span onClick={internalOnClick}
                 className="text-primary text-decoration-underline"
                 style={{ cursor: "pointer" }}>
        {children}
    </span>;
}