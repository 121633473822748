import { Container, Row, Col } from "react-bootstrap";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

export function SandboxPage() {
    return <Container>
        <Row>
            <Col>
                Yooooo
            </Col>
        </Row>
    </Container>    
}