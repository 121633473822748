import { OrderType } from "./ApiTransport"; 
import { PaymentType } from "./ApiTransport"; 
import { OrderInitiator } from "./ApiTransport"; 
import { OrderBalanceType } from "./ApiTransport"; import { OperatorApi } from "./ApiTransport"; 
import { FacilityInfoApi } from "./ApiTransport"; 
import { UsAddressApi } from "./ApiTransport"; 
import { CoordinatesApi } from "./ApiTransport"; 
import { VehicleApi } from "./ApiTransport"; 
import { RateProgramApi } from "./ApiTransport"; 
import { RateEntryApi } from "./ApiTransport"; 
import { FacilityImageApi } from "./ApiTransport"; 
import { ListedPlanApi } from "./ApiTransport"; 
import { OrderDetailsApi } from "./ApiTransport"; 
import { OrderApi } from "./ApiTransport"; 
import { OrderBalanceApi } from "./ApiTransport"; 
import { InvoiceApi } from "./ApiTransport"; 
import { PaymentApi } from "./ApiTransport"; 
import { ApplyPaymentApi } from "./ApiTransport"; 
import { OrderInfoApi } from "./ApiTransport"; 
import { PaymentChangeApi } from "./ApiTransport"; 
import { OrderDebtApi } from "./ApiTransport"; 
import { SignUpReqApi } from "./ApiTransport"; 
import { LogInReqApi } from "./ApiTransport"; 
import { ChangeEmailReqApi } from "./ApiTransport"; 
import { ChangePasswordReqApi } from "./ApiTransport"; 
import { ForgotPasswordReqApi } from "./ApiTransport"; 
import { ResetPasswordReqApi } from "./ApiTransport"; 
import { ResetCodeCheckReqApi } from "./ApiTransport"; 
import { ParkerApi } from "./ApiTransport"; 
import { FacilityApi } from "./ApiTransport"; 
import { SubscriptionApi } from "./ApiTransport"; 
import { SubscriptionEntryApi } from "./ApiTransport"; 
import { PlanInfoApi } from "./ApiTransport"; 
import { ReservationApi } from "./ApiTransport"; 
import { ReserveInitReqApi } from "./ApiTransport"; 
import { ReserveInitRespApi } from "./ApiTransport"; 
import { ReserveConfirmReqApi } from "./ApiTransport"; 
import { ReserveConfirmRespApi } from "./ApiTransport"; 
import { SubscribeInitReqApi } from "./ApiTransport"; 
import { SubscribeInitRespApi } from "./ApiTransport"; 
import { SubscribeConfirmReqApi } from "./ApiTransport"; 
import { SubscribeConfirmRespApi } from "./ApiTransport"; 
import { OrderPayInitReqApi } from "./ApiTransport"; 
import { OrderPayInitRespApi } from "./ApiTransport"; 
import { SettleInitReqApi } from "./ApiTransport"; 
import { SettleInitRespApi } from "./ApiTransport"; 
import { FacilityParkerBalanceApi } from "./ApiTransport"; 
import { AccessPeriodInfoApi } from "./ApiTransport"; import { Operator } from "./ApiTypes"; 
import { FacilityInfo } from "./ApiTypes"; 
import { UsAddress } from "./ApiTypes"; 
import { Coordinates } from "./ApiTypes"; 
import { Vehicle } from "./ApiTypes"; 
import { RateProgram } from "./ApiTypes"; 
import { RateEntry } from "./ApiTypes"; 
import { FacilityImage } from "./ApiTypes"; 
import { ListedPlan } from "./ApiTypes"; 
import { OrderDetails } from "./ApiTypes"; 
import { Order } from "./ApiTypes"; 
import { OrderBalance } from "./ApiTypes"; 
import { Invoice } from "./ApiTypes"; 
import { Payment } from "./ApiTypes"; 
import { ApplyPayment } from "./ApiTypes"; 
import { OrderInfo } from "./ApiTypes"; 
import { PaymentChange } from "./ApiTypes"; 
import { OrderDebt } from "./ApiTypes"; 
import { SignUpReq } from "./ApiTypes"; 
import { LogInReq } from "./ApiTypes"; 
import { ChangeEmailReq } from "./ApiTypes"; 
import { ChangePasswordReq } from "./ApiTypes"; 
import { ForgotPasswordReq } from "./ApiTypes"; 
import { ResetPasswordReq } from "./ApiTypes"; 
import { ResetCodeCheckReq } from "./ApiTypes"; 
import { Parker } from "./ApiTypes"; 
import { Facility } from "./ApiTypes"; 
import { Subscription } from "./ApiTypes"; 
import { SubscriptionEntry } from "./ApiTypes"; 
import { PlanInfo } from "./ApiTypes"; 
import { Reservation } from "./ApiTypes"; 
import { ReserveInitReq } from "./ApiTypes"; 
import { ReserveInitResp } from "./ApiTypes"; 
import { ReserveConfirmReq } from "./ApiTypes"; 
import { ReserveConfirmResp } from "./ApiTypes"; 
import { SubscribeInitReq } from "./ApiTypes"; 
import { SubscribeInitResp } from "./ApiTypes"; 
import { SubscribeConfirmReq } from "./ApiTypes"; 
import { SubscribeConfirmResp } from "./ApiTypes"; 
import { OrderPayInitReq } from "./ApiTypes"; 
import { OrderPayInitResp } from "./ApiTypes"; 
import { SettleInitReq } from "./ApiTypes"; 
import { SettleInitResp } from "./ApiTypes"; 
import { FacilityParkerBalance } from "./ApiTypes"; 
import { AccessPeriodInfo } from "./ApiTypes"; 
import { cents } from "./Money";
import { createDuration, createLocalDateTime, createLocalTime, createLocalDate, createInstant } from "./ApiConverters";
import { Duration } from "js-joda";export function parseOperator( o: OperatorApi ): Operator {
    return {
        operatorId: o.operatorId,
        name: o.name,
        address: parseUsAddress( o.address ),
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber,
        website: o.website,
        topTitle: o.topTitle,
        topText: o.topText,
        bottomLeftTitle: o.bottomLeftTitle,
        bottomLeftText: o.bottomLeftText,
        bottomRightTitle: o.bottomRightTitle,
        bottomRightText: o.bottomRightText,
    }
}

export function parseFacilityInfo( f: FacilityInfoApi ): FacilityInfo {
    return {
        facilityId: f.facilityId,
        operatorId: f.operatorId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: parseUsAddress( f.address ),
        coordinates: parseCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
    }
}

export function parseUsAddress( u: UsAddressApi ): UsAddress {
    return {
        addressLine1: u.addressLine1,
        addressLine2: u.addressLine2,
        city: u.city,
        state: u.state,
        zipCode: u.zipCode,
    }
}

export function parseCoordinates( c: CoordinatesApi ): Coordinates {
    return {
        lat: c.lat,
        lng: c.lng,
    }
}

export function parseVehicle( v: VehicleApi ): Vehicle {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
    }
}

export function parseRateProgram( r: RateProgramApi ): RateProgram {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: createLocalDateTime( r.start ),
        end: r.end != null ? createLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? createLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? createLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? createLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? createLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => parseRateEntry( el ) ),
    }
}

export function parseRateEntry( r: RateEntryApi ): RateEntry {
    return {
        rateEntryId: r.rateEntryId,
        rateProgramId: r.rateProgramId,
        upTo: Duration.ofMinutes( r.upTo ),
        rate: cents( r.rate ),
    }
}

export function parseFacilityImage( f: FacilityImageApi ): FacilityImage {
    return {
        facilityImageId: f.facilityImageId,
        storedImageId: f.storedImageId,
        orderId: f.orderId,
        caption: f.caption,
        altText: f.altText,
    }
}

export function parseListedPlan( l: ListedPlanApi ): ListedPlan {
    return {
        planId: l.planId,
        planEntryId: l.planEntryId,
        name: l.name,
        description: l.description,
        accessHours: l.accessHours,
        dayOfMonthInvoice: l.dayOfMonthInvoice,
        dayOfMonthDue: l.dayOfMonthDue,
        dayOfMonthLate: l.dayOfMonthLate,
        price: cents( l.price ),
    }
}

export function parseOrderDetails( o: OrderDetailsApi ): OrderDetails {
    return {
        order: parseOrder( o.order ),
        balanceChanges: o.balanceChanges.map( el => parseOrderBalance( el ) ),
        summary: o.summary,
    }
}

export function parseOrder( o: OrderApi ): Order {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: cents( o.price ),
        createdWhen: createInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        type: o.type,
        facility: parseFacilityInfo( o.facility ),
        submittedWhen: o.submittedWhen != null ? createInstant( o.submittedWhen ) : null,
        balance: parseOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function parseOrderBalance( o: OrderBalanceApi ): OrderBalance {
    return {
        orderBalanceId: o.orderBalanceId,
        type: o.type,
        orderId: o.orderId,
        createdWhen: createInstant( o.createdWhen ),
        debt: cents( o.debt ),
        paid: cents( o.paid ),
        balance: cents( o.balance ),
        totalDebt: cents( o.totalDebt ),
        totalPaid: cents( o.totalPaid ),
        reason: o.reason != null ? o.reason : null,
        paymentId: o.paymentId != null ? o.paymentId : null,
    }
}

export function parseInvoice( i: InvoiceApi ): Invoice {
    return {
        invoiceId: i.invoiceId,
        orderId: i.orderId,
        createdWhen: createInstant( i.createdWhen ),
        dueWhen: createLocalDate( i.dueWhen ),
        openingBalance: cents( i.openingBalance ),
        endingBalance: cents( i.endingBalance ),
        order: parseOrderInfo( i.order ),
        facility: parseFacilityInfo( i.facility ),
    }
}

export function parsePayment( p: PaymentApi ): Payment {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        facility: parseFacilityInfo( p.facility ),
        parkerId: p.parkerId,
        type: p.type,
        amount: cents( p.amount ),
        balance: cents( p.balance ),
        remaining: cents( p.remaining ),
        paymentMethod: p.paymentMethod,
        changes: p.changes.map( el => parsePaymentChange( el ) ),
        applications: p.applications.map( el => parseApplyPayment( el ) ),
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseApplyPayment( a: ApplyPaymentApi ): ApplyPayment {
    return {
        orderBalanceId: a.orderBalanceId,
        type: a.type,
        facilityId: a.facilityId,
        parkerId: a.parkerId,
        orderId: a.orderId,
        debt: cents( a.debt ),
        paid: cents( a.paid ),
        balance: cents( a.balance ),
        totalDebt: cents( a.totalDebt ),
        totalPaid: cents( a.totalPaid ),
    }
}

export function parseOrderInfo( o: OrderInfoApi ): OrderInfo {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: cents( o.price ),
        createdWhen: createInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        orderType: o.orderType,
        submittedWhen: o.submittedWhen != null ? createInstant( o.submittedWhen ) : null,
        balance: parseOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function parsePaymentChange( p: PaymentChangeApi ): PaymentChange {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        parkerId: p.parkerId,
        type: p.type,
        amount: cents( p.amount ),
        balance: cents( p.balance ),
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseOrderDebt( o: OrderDebtApi ): OrderDebt {
    return {
        orderId: o.orderId,
        createdWhen: createInstant( o.createdWhen ),
        orderTotal: cents( o.orderTotal ),
        paid: cents( o.paid ),
        unpaid: cents( o.unpaid ),
    }
}

export function parseSignUpReq( s: SignUpReqApi ): SignUpReq {
    return {
        name: s.name,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber,
        password: s.password,
        passwordConfirm: s.passwordConfirm,
    }
}

export function parseLogInReq( l: LogInReqApi ): LogInReq {
    return {
        emailAddress: l.emailAddress,
        password: l.password,
    }
}

export function parseChangeEmailReq( c: ChangeEmailReqApi ): ChangeEmailReq {
    return {
        currentPassword: c.currentPassword,
        newEmailAddress: c.newEmailAddress,
    }
}

export function parseChangePasswordReq( c: ChangePasswordReqApi ): ChangePasswordReq {
    return {
        currentPassword: c.currentPassword,
        newPassword: c.newPassword,
    }
}

export function parseForgotPasswordReq( f: ForgotPasswordReqApi ): ForgotPasswordReq {
    return {
        emailAddress: f.emailAddress,
    }
}

export function parseResetPasswordReq( r: ResetPasswordReqApi ): ResetPasswordReq {
    return {
        resetPasswordCode: r.resetPasswordCode,
        newPassword: r.newPassword,
    }
}

export function parseResetCodeCheckReq( r: ResetCodeCheckReqApi ): ResetCodeCheckReq {
    return {
        resetPasswordCode: r.resetPasswordCode,
    }
}

export function parseParker( p: ParkerApi ): Parker {
    return {
        parkerId: p.parkerId,
        operatorId: p.operatorId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber,
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseFacility( f: FacilityApi ): Facility {
    return {
        facilityId: f.facilityId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: parseUsAddress( f.address ),
        coordinates: parseCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        physicalCapacity: f.physicalCapacity,
        enforcedCapacity: f.enforcedCapacity,
        images: f.images.map( el => parseFacilityImage( el ) ),
        hourlyRates: f.hourlyRates.map( el => parseRateProgram( el ) ),
        listedPlans: f.listedPlans.map( el => parseListedPlan( el ) ),
    }
}

export function parseSubscription( s: SubscriptionApi ): Subscription {
    return {
        subscriptionId: s.subscriptionId,
        start: createLocalDate( s.start ),
        currentEntry: parseSubscriptionEntry( s.currentEntry ),
        history: s.history.map( el => parseSubscriptionEntry( el ) ),
        facility: parseFacilityInfo( s.facility ),
        accessPeriods: s.accessPeriods.map( el => parseAccessPeriodInfo( el ) ),
    }
}

export function parseSubscriptionEntry( s: SubscriptionEntryApi ): SubscriptionEntry {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        start: createLocalDate( s.start ),
        end: s.end != null ? createLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        accessPeriods: s.accessPeriods.map( el => parseAccessPeriodInfo( el ) ),
        price: cents( s.price ),
        plan: parsePlanInfo( s.plan ),
    }
}

export function parsePlanInfo( p: PlanInfoApi ): PlanInfo {
    return {
        planId: p.planId,
        facilityId: p.facilityId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
    }
}

export function parseReservation( r: ReservationApi ): Reservation {
    return {
        reservationId: r.reservationId,
        facilityId: r.facilityId,
        parkerId: r.parkerId,
        start: createLocalDateTime( r.start ),
        end: createLocalDateTime( r.end ),
        actualStart: createInstant( r.actualStart ),
        actualEnd: createInstant( r.actualEnd ),
        duration: Duration.ofMinutes( r.duration ),
        rateProgramId: r.rateProgramId,
        price: cents( r.price ),
        createdWhen: createInstant( r.createdWhen ),
        facility: parseFacilityInfo( r.facility ),
        rateProgram: parseRateProgram( r.rateProgram ),
    }
}

export function parseReserveInitReq( r: ReserveInitReqApi ): ReserveInitReq {
    return {
        facilityId: r.facilityId,
        start: createLocalDateTime( r.start ),
        end: createLocalDateTime( r.end ),
    }
}

export function parseReserveInitResp( r: ReserveInitRespApi ): ReserveInitResp {
    return {
        facilityId: r.facilityId,
        start: createLocalDateTime( r.start ),
        end: createLocalDateTime( r.end ),
        stayDuration: Duration.ofMinutes( r.stayDuration ),
        chargeDuration: Duration.ofMinutes( r.chargeDuration ),
        orderId: r.orderId,
        price: cents( r.price ),
        customerSessionClientSecret: r.customerSessionClientSecret,
        paymentIntentClientSecret: r.paymentIntentClientSecret,
    }
}

export function parseReserveConfirmReq( r: ReserveConfirmReqApi ): ReserveConfirmReq {
    return {
        orderId: r.orderId,
    }
}

export function parseReserveConfirmResp( r: ReserveConfirmRespApi ): ReserveConfirmResp {
    return {
        statusMsg: r.statusMsg,
    }
}

export function parseSubscribeInitReq( s: SubscribeInitReqApi ): SubscribeInitReq {
    return {
        facilityId: s.facilityId,
        planEntryId: s.planEntryId,
        start: createLocalDate( s.start ),
        quantity: s.quantity,
    }
}

export function parseSubscribeInitResp( s: SubscribeInitRespApi ): SubscribeInitResp {
    return {
        facilityId: s.facilityId,
        planEntryId: s.planEntryId,
        start: createLocalDate( s.start ),
        quantity: s.quantity,
        price: cents( s.price ),
        orderId: s.orderId,
        paymentIntentClientSecret: s.paymentIntentClientSecret,
        customerSessionClientSecret: s.customerSessionClientSecret,
    }
}

export function parseSubscribeConfirmReq( s: SubscribeConfirmReqApi ): SubscribeConfirmReq {
    return {
        orderId: s.orderId,
    }
}

export function parseSubscribeConfirmResp( s: SubscribeConfirmRespApi ): SubscribeConfirmResp {
    return {
        status: s.status,
    }
}

export function parseOrderPayInitReq( o: OrderPayInitReqApi ): OrderPayInitReq {
    return {
        orderId: o.orderId,
    }
}

export function parseOrderPayInitResp( o: OrderPayInitRespApi ): OrderPayInitResp {
    return {
        orderId: o.orderId,
        price: cents( o.price ),
        paymentIntentClientSecret: o.paymentIntentClientSecret,
        customerSessionClientSecret: o.customerSessionClientSecret,
    }
}

export function parseSettleInitReq( s: SettleInitReqApi ): SettleInitReq {
    return {
        facilityId: s.facilityId,
    }
}

export function parseSettleInitResp( s: SettleInitRespApi ): SettleInitResp {
    return {
        facility: parseFacilityInfo( s.facility ),
        orders: s.orders.map( el => parseOrder( el ) ),
        price: cents( s.price ),
        paymentIntentClientSecret: s.paymentIntentClientSecret,
        customerSessionClientSecret: s.customerSessionClientSecret,
    }
}

export function parseFacilityParkerBalance( f: FacilityParkerBalanceApi ): FacilityParkerBalance {
    return {
        facility: parseFacilityInfo( f.facility ),
        balance: cents( f.balance ),
        orderDebts: f.orderDebts.map( el => parseOrderDebt( el ) ),
    }
}

export function parseAccessPeriodInfo( a: AccessPeriodInfoApi ): AccessPeriodInfo {
    return {
        accessPeriodId: a.accessPeriodId,
        subscriptionEntryId: a.subscriptionEntryId,
        start: createLocalDate( a.start ),
        end: createLocalDate( a.end ),
        isDelivered: a.isDelivered,
        price: cents( a.price ),
    }
}


