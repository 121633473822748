//third party
import { Duration, LocalDateTime, LocalTime, Period } from "js-joda";
import { add } from "dinero.js";

//spotsync
import { cents } from "./Money";
import { gt, lte, max, min } from "./Duration";
import { RateProgram as RateProgram } from "./ApiTypes";

export function findReset( prog: RateProgram ) {
    return prog.entries.map( r => r.upTo ).reduce( (a, b) => max( a, b ), prog.entries[0].upTo );
}

function GetPeriod( a: LocalTime ): Duration {
    const h = a.hour();
    const m = a.minute();
    const s = a.second();
    const diff = Duration.ofHours( h ).plusMinutes( m ).plusSeconds( s );
    return diff;
}

function Between(t: LocalTime, a: LocalTime, b: LocalTime): boolean {
    if (a == b)
        return true;
    const diff = GetPeriod(a);
    const an = LocalTime.MIDNIGHT;
    const bn = b.minus( diff );
    const tn = t.minus( diff );
    return tn > an && tn < bn;
}

export function computeCharge( prog: RateProgram,
                               a:    LocalDateTime,
                               b:    LocalDateTime  ) {
    const dur     = Duration.between( a, b );
    const reset   = findReset( prog );
    const entries = prog.entries;
    let  fee      = cents( 0 );
    let  time     = Duration.from( dur );
    while( gt( time, Duration.ZERO ) ) { //safety is there for development only
        const chunk    = min( reset, time );
        const minEntry = entries.find( entry => lte( chunk, entry.upTo ) );
        fee            = add( fee, minEntry!.rate );
        time           = time.minusDuration( chunk );
    }
    return fee;
}