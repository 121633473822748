import { ChronoField, ChronoUnit, DateTimeFormatter, Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from "js-joda";
import { concatWords, pluralize } from "./English";
import { DateTime } from "luxon";
import { emitInstant } from "./ApiConverters";

const browserDateFormat = DateTimeFormatter.ofPattern( "yyyy-MM-dd" );
const browserTimeFormat = DateTimeFormatter.ofPattern( "HH:mm"      );
const dateFormat        = DateTimeFormatter.ofPattern( "MM/dd/yyyy" );
const timeFormat        = DateTimeFormatter.ofPattern( "h:mm"       );
const shortTimeFormat   = DateTimeFormatter.ofPattern( "h"          );

export const splitDuration = ( d: Duration ) => {
    const h  = d.toHours();
    const m  = d.minusHours( h ).toMinutes();
    const s  = d.minusMinutes( m ).seconds();
    const ms = d.minusSeconds( s ).toMillis();
    return [h, m, s, ms];
}

export function showDuration( d: Duration ) {
    const [h, m] = splitDuration( d );

    //word pluralization
    const hWord = `${pluralize( h, "hour",   "hours"   )}`;
    const mWord = `${pluralize( m, "minute", "minutes" )}`;

    //string handling
    const hs = h > 0 ? `${h} ${hWord}` : "";
    const ms = m > 0 ? `${m} ${mWord}` : "";
    return concatWords( hs, ms );
}

export function showDurationShort( d: Duration ) {
    const [h, m] = splitDuration( d );

    //string handling
    const hs = h > 0 ? `${h}h` : "";
    const ms = m > 0 ? `${m}m` : "";
    if( hs === "" && ms === "" ) {
        return "0m";
    }
    return concatWords( hs, ms );
}

export function showTime( t: LocalTime ) {
    const am   = t.isBefore( LocalTime.of( 12, 0, 0, 0 ) );
    const amPm = am ? "am" : "pm";
    return `${t.format( timeFormat )} ${amPm}`;
}

export function showTimeShort( t: LocalTime ) {
    const am   = t.isBefore( LocalTime.of( 12, 0, 0, 0 ) );
    const amPm = am ? "am" : "pm";
    if( t.minute() === 0 ) {
        return `${t.format( shortTimeFormat )} ${amPm}`;
    }
    return `${t.format( timeFormat )} ${amPm}`;
}

export function showBrowserTime( d: LocalTime ) {
    return browserTimeFormat.format( d );
}

export function showBrowserDate( d: LocalDate ) {
    return browserDateFormat.format( d );
}

export function showDate( d: LocalDate ) {
    const today    = LocalDate.now();
    const tomorrow = today.plusDays(  1 );
    if( d.equals( today    ) ) { return "Today";    }
    if( d.equals( tomorrow ) ) { return "Tomorrow"; }
    //else
    return dateFormat.format( d );
}

export function showDateTime( d: LocalDateTime ) {
    const today = LocalDate.now();
    const isToday = d.toLocalDate().equals( today );
    const words: string[] = [];
    words.push( isToday ? "Today" : dateFormat.format( d ) );
    words.push( showTime( d.toLocalTime() ) );
    return concatWords( ...words );
}

export function showStartEndShort( a: LocalDateTime, b: LocalDateTime ) {
    const [ald, bld] = [a, b].map( dt => dt.toLocalDate() );
    const [ats, bts] = [a, b].map( dt => showTimeShort( dt.toLocalTime() ) );
    const [ads, bds] = [a, b].map( dt => showDate( dt.toLocalDate() ) );
    if( ald.equals( bld ) ) {
        //pattern: da from ta to tb
        return `${ads} from ${ats} to ${bts}`;
    }
    //pattern: da ta to db tb
    return `${ads} from ${ats} to ${bds} at ${bts}`;
}

export function showStartEnd( a: LocalDateTime, b: LocalDateTime ) {
    const [ald, bld] = [a, b].map( dt => dt.toLocalDate() );
    const [ats, bts] = [a, b].map( dt => showTime( dt.toLocalTime() ) );
    const [ads, bds] = [a, b].map( dt => showDate( dt.toLocalDate() ) );
    if( ald.equals( bld ) ) {
        //pattern: da from ta to tb
        return `${ads} from ${ats} to ${bts}`;
    }
    //pattern: da ta to db tb
    return `${ads} from ${ats} to ${bds} at ${bts}`;
}

export function showStartEndLocalTime( a: LocalTime, b: LocalTime ) {
    const [ats, bts] = [a, b].map( lt => showTime( lt ) );
    return `${ats} - ${bts}`;
}

export function showStartEndLocalTimeShort( a: LocalTime, b: LocalTime ) {
    const [ats, bts] = [a, b].map( lt => showTimeShort( lt ) );
    return `${ats} - ${bts}`;
}

export function showInBetweenOutBetween( a: LocalTime, b: LocalTime, c: LocalTime, d: LocalTime ): { inRange: string, outRange: string } {
    const [ats, bts, cts, dts] = [a, b, c, d].map( lt => showTime( lt ) );
    return { inRange: `${ats} - ${bts}`, outRange: `${cts} - ${dts}` };
}

export function getStartOfDay( dt: LocalDateTime ) {
    return dt.toLocalDate().atTime( LocalTime.MIN )
}

//I think the first day of the week
export function getStartOfWeek( dt: LocalDateTime ) {
    return dt.with( ChronoField.DAY_OF_WEEK, 1 ).truncatedTo( ChronoUnit.DAYS );
}

export function getStartOfMonth( dt: LocalDateTime ) {
    return dt.withDayOfMonth( 1 ).truncatedTo( ChronoUnit.DAYS );
}

function getFirstOfYear( dateTime: LocalDateTime ) {
    return dateTime.withDayOfYear( 1 ).truncatedTo( ChronoUnit.DAYS );
}

export function createLocalTime( date: string ) {
    const localDate = LocalTime.parse( date );
    return localDate;
}

export function createLocalDate( date: string ) {
    const localDate = LocalDate.parse( date );
    return localDate;
}

export function createLocalDateTime( date: string, time: string ) {
    const localDate     = LocalDate.parse(  date       );
    const localTime     = LocalTime.parse(  time       );
    const localDateTime = LocalDateTime.of( localDate, localTime );
    return localDateTime;
}

export function tryCreateLocalTime( time: string ): LocalTime | null {
    try {
        return LocalTime.parse( time );
    }
    catch( error ) {
        return null;
    }
}

export function tryCreateLocalDate( date: string ): LocalDate | null {
    try {
        return LocalDate.parse( date );
    }
    catch( error ) {
        return null;
    }
}

export function tryCreateLocalDateTime( date: string, time: string ): LocalDateTime | null {
    try {
        const localDate = LocalDate.parse( date );
        const localTime = LocalTime.parse( time );
        return LocalDateTime.of( localDate, localTime );
    }
    catch( error ) {
        return null;
    }
}

export function showInstant( instantStr: Instant, zone: string ) {
    return DateTime.fromISO( emitInstant( instantStr ), { zone: 'utc' })
                   .setZone( zone )
                   .toFormat("MM/dd/yyyy h:mm a");
}

export function showInstantLocal( instant: Instant ) {
    return DateTime.fromISO( emitInstant( instant ) )
        .toLocal()
        .toFormat( "MM/dd/yyyy h:mm a" );
}

export function showInstantLocalDate( instantStr: Instant ) {
    return DateTime.fromISO( emitInstant( instantStr ) )
        .toLocal()
        .toFormat( "MM/dd/yyyy" );
}

//round a luxon DateTime to nearest 30 minutes
export function nearest30( time: LocalDateTime ): LocalDateTime {
    const minutes:   number = time.minute();
    const remainder: number = minutes % 30;
    return remainder < 15
        ? time.minusMinutes( remainder )
        : time.plusMinutes( 30 - remainder );
}