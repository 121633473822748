import { useAtomValue } from "jotai";
import { hourlyCheckoutAtom, hourlyFormAtom, monthlyCheckoutAtom, monthlyFormAtom, tabAtom } from "./Data/Atoms";
import { Card, Container } from "react-bootstrap";

export function AtomDebugPage() {
    const tab             = useAtomValue( tabAtom             );
    const hourlyForm      = useAtomValue( hourlyFormAtom      );
    const monthlyForm     = useAtomValue( monthlyFormAtom     );
    const hourlyCheckout  = useAtomValue( hourlyCheckoutAtom  );
    const monthlyCheckout = useAtomValue( monthlyCheckoutAtom );

    const atoms: { [x: string]: any } = {
        tab,
        hourlyForm,
        monthlyForm,
        hourlyCheckout,
        monthlyCheckout
    };

    return <Container fluid>
        {Object.keys( atoms ).map( key => <Card className="m-2">
            <Card.Header>
                {key}
            </Card.Header>
            <Card.Body>
                <pre>{JSON.stringify( atoms[key] as any, null, 4 ) ?? "".trim()}</pre>
            </Card.Body>
        </Card> ) }
    </Container>
}