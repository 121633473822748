//third-party
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLoaderData, useParams       } from "react-router-dom";

//spotsync connect
import { Api, VehicleData } from "./Data/Api";
import { Button          } from "./Controls/Button";
import { del, push       } from "./Data/Array";
import { AddVehicleModal } from "./Controls/AddVehicleModal";
import { VehicleCard     } from "./Controls/VehicleCard";
import { PageTitle       } from "./Controls/PageTitle";
import { processResult   } from "./Data/Result";
import { Vehicle } from "./Data/ApiTypes";

export function UserVehiclesPage() {
    const vs = useLoaderData() as Vehicle[];
    const [vehicles, setVehicles] = useState<Vehicle[]>( vs );
    const [showAddVehicle, setShowAddVehicle] = useState( false );

    function addVehicle( vehicle: VehicleData ) {
        Api.vehicleAdd( vehicle ).then( res => processResult( res, v => {
            setVehicles( push( vehicles, v as Vehicle ) );
        } ) );
    }

    function deleteVehicle( vehicle: Vehicle ) {
        Api.vehicleDelete( vehicle.vehicleId ).then( res => processResult( res, _ => {
            setVehicles( del( vehicles, vehicle ) );
        } ) );
    }

    return <Container fluid>
        <Row>
            <Col>
                <AddVehicleModal show={showAddVehicle} setShow={setShowAddVehicle} onSubmit={addVehicle} />
                <PageTitle>
                    My Vehicles
                </PageTitle>
                <div className="pb-1">
                    <div className="text-secondary">
                        Use this page to add or remove vehicles from your account.
                    </div>
                </div>
                {vehicles.length === 0 && <>
                    You don't have any vehicles
                </>}
                {vehicles.map( ( veh, index ) =>
                    <VehicleCard key={index}
                                 vehicle={veh}
                                 deleteVehicle={deleteVehicle}
                                 vehicles={vehicles} />
                )}
                <div className="text-end">
                    <Button className="w-100" onClick={ () => setShowAddVehicle( true ) }>Add Vehicle</Button>
                </div>
            </Col>
        </Row>
    </Container>;
}