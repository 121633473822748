//third-party
import { allocate, multiply    } from "dinero.js";
import { LocalDate, YearMonth  } from "js-joda";
import { Card, Form, ListGroup } from "react-bootstrap";

//spotsync
import { showMoney, showMoneyShort } from "../Data/Money";
import { showDate } from "../Data/Date";
import { ListedPlan, PlanInfo } from "../Data/ApiTypes";
import { AddSubtract } from "./AddSubtract";

interface MonthlyParkingDetailsProps {
    color?:         string;
    start:          LocalDate;
    listedPlans:    ListedPlan[];
    selOpt:         ListedPlan;
    setSelOpt:      ( plan: ListedPlan ) => void;
    disabled?:      boolean;
    quantity?:      number;
    setQuantity?:   ( qty: number ) => void;
}



function prorate( listedPlan: ListedPlan, start: LocalDate, quantity: number | undefined ) {
    const dayOfMonDue  = listedPlan.dayOfMonthDue;
    const nextMon      = YearMonth.from( start ).plusMonths( 1 ).atDay( dayOfMonDue );
    const daysTill     = nextMon.toEpochDay() - start.toEpochDay();
    const daysInCurMon = 30; //YearMonth.from( start ).lengthOfMonth();
    const rate         = multiply( listedPlan.price, quantity ?? 1 );
    const [proratedFee, _] = allocate( rate, [daysTill, daysInCurMon - daysTill ] );
    console.log(  )
    return { rate, proratedFee };
}

export function MonthlyParkingDetails( props: MonthlyParkingDetailsProps ) {
    const { color = "primary",
            start,
            listedPlans: monthlyOptions,
            selOpt,
            setSelOpt,
            quantity,
            setQuantity,
            disabled = false } = { ...props };
    const { rate, proratedFee } = prorate( selOpt, start, quantity );
    return <Card className={`border-2 border-${color} my-1`}>
        <Card.Header className={`text-center bg-${color}-subtle`}>
            Monthly Parking Options
        </Card.Header>
        <Card.Body>
            {monthlyOptions.map( listedPlan =>
                <ListGroup.Item key={listedPlan.planEntryId}>
                    <div className="d-flex flex-row border rounded-4 p-3 m-2 fs-7"
                        onClick={() => !disabled && setSelOpt( listedPlan )}>
                        <Form.Check
                            className="m-0 flex-grow-1"
                            type="radio"
                            label={`${listedPlan.name}`}
                            checked={listedPlan === selOpt}
                            inline
                            readOnly />
                        <span className="fw-bold">
                            {showMoneyShort( listedPlan.price )}
                        </span>
                    </div>
                </ListGroup.Item> )}
            <div className="text-secondary">
                {selOpt.description}
            </div>
            <div className="mt-2 w-auto gap-3" style={{ display: "inline-grid", gridTemplateColumns: "auto 1fr 1fr" }}>
                {( quantity !== undefined && setQuantity !== undefined ) && <>
                    <div className="text-start d-flex align-items-center">
                        Number of Spots
                        {/* {pluralize( quantity, "Spot", "Spots" )} */}
                    </div>
                    <div className="text-end">
                        <div className="d-inline-flex align-items-center justify-content-start justify-content-md-center gap-1">
                            <AddSubtract quantity={quantity} set={ qty => disabled !== true && setQuantity( qty ) } />
                        </div>
                    </div>
                    <div></div>
                </>}

                <div className="text-start">Start Date</div>
                <div className="text-end">{showDate( start )}</div>
                <div></div>
                {/* <div className="monthly-sub-info-text text-secondary">The first day you can park.</div>
                <div></div> */}

                <div className="text-start">First Payment</div>
                <div className="text-end fw-bold">{showMoney( proratedFee )}</div>
                <div></div>
                {/*
                <div className="monthly-sub-info-text text-secondary">What you will pay today to be able to park until the end of the month.</div>
                <div></div>
                 */}
                <div className="text-start">Monthly Payment</div>
                <div className="text-end fw-bold">{showMoney( rate )}</div>
                {/* <div className="monthly-sub-info-text text-secondary">What you will pay on the next monthly billing date, this pays for the next month.</div>
                <div></div> */}
            </div>
        </Card.Body>
    </Card>;
}