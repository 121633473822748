//third-party
import { Duration, LocalDateTime           } from "js-joda";
import { useNavigate        } from "react-router-dom";
import { Badge, Card               } from "react-bootstrap";

//spotsync
import { showDurationShort, showStartEndShort  } from "../Data/Date";
import { showAddressHtml    } from "../Data/Location";
import { showMoney          } from "../Data/Money";
import { Reservation } from "../Data/ApiTypes";
import { facilityImg } from "../Data/Common";

export function ReservationCard( { reservation: r }:  { reservation: Reservation } ) {
    const nav        = useNavigate();
    const now        = LocalDateTime.now();
    const isInFuture = LocalDateTime.now().compareTo( r.start ) < 0;
    const isActive   = r.start.compareTo( now ) < 0 && r.end.compareTo( now ) > 0;
    const f          = r.facility;
    return <Card className="mb-2" style={{ cursor: "pointer" }}
                 onClick={ () => nav(`/user/reservation/details/${r.reservationId}`) }>
        <Card.Header className="text-start fw-bold d-flex align-items-center gap-1">
            {isActive && <Badge>In Progress</Badge>} {showStartEndShort( r.start, r.end )}
        </Card.Header>
        <Card.Body className="purchase-layout gap-2">
            <div className="grid-area-a">
                <img className="facility-result-pic"
                    src={facilityImg( f.listImageId )}
                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
            </div>
            <div className="grid-area-b">
                <div className="fw-bold">{f.name}</div>
                {showAddressHtml( f.address )}
            </div>
            <div className="grid-area-c position-relative fs-4" style={{ justifySelf: "end" }}>
                <div className="fw-bold">
                    {showMoney( r.price )}
                </div>
                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }}>
                    for {showDurationShort( r.duration )}
                </div>
            </div>
        </Card.Body>
    </Card>;
}