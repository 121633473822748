import { Form, Modal } from "react-bootstrap";
import { Amenity, AmenityIcon, allAmenities } from "../Data/Amenity";
import { Button } from "./Button";
import { HeaderText } from "./HeaderText";

export interface AmenitiesModalProps {
    show:         boolean;
    setShow:      ( value: boolean ) => void;
    amenities:    Amenity[];
    setAmenities: React.Dispatch<React.SetStateAction<Amenity[]>>;
}


export function AmenitiesModal( props: AmenitiesModalProps ) {
    const { show, setShow, amenities, setAmenities } = { ...props };
    function modifyAmenities( amenity: Amenity, checked: boolean ) {
        if( checked ) {
            setAmenities( [...amenities, amenity] );
            return;
        }
        setAmenities( amenities.filter( el => el !== amenity ) );
    }

    function AmenityButton( { amenity }: { amenity: Amenity }) {
        const chk = amenities.findIndex( el => el === amenity ) !== -1;
        return <div 
            className="d-flex align-items-center gap-1"
            onClick={( e ) => modifyAmenities( amenity, !chk )}>
            {/* the () => 0 is to suppress a stupid warning */}
            <Form.Check onChange={ () => 0 } checked={chk} className="fs-5" />
            <AmenityIcon style={{ stroke: "1px blue solid" }} className="m-1" amenity={amenity} />
            {amenity}
        </div>;
    }

    return <Modal show={show} 
                  centered
                  keyboard
                  onEscapeKeyDown={ () => setShow( false ) }>
        <Modal.Header>
            <HeaderText>Amenities</HeaderText>
        </Modal.Header>
        <Modal.Body style={{ display:             "grid", 
                             gridAutoFlow:        "column", 
                             gridTemplateColumns: "1fr 1fr",
                             gridTemplateRows:    "repeat( 5, 1fr )",
                             rowGap:              "1.5em" }}>
            {allAmenities.map( (amenity, i) => {
                return <AmenityButton key={i} amenity={amenity} />
            } )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
            <Button className="flex-grow-1" onClick={ () => setAmenities( [] )}>
                Clear
            </Button>
            <Button className="flex-grow-1" onClick={ () => setShow( false )}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>;
}