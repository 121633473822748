import { Container, Row, Col, Card } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { facilityImg, useParamIds } from "./Data/Common";
import { showMoney } from "./Data/Money";
import { showTime, showDate } from "./Data/Date";
import { showAddressHtml } from "./Data/Location";
import { getStateEnumFromCode, showStateName } from "./Data/UsState";
import { Button } from "./Controls/Button";
import { MapAddress } from "./Controls/MapAddress";
import { useLoaderData } from "react-router-dom";
import { Reservation, Vehicle } from "./Data/ApiTypes";

export interface ReservationVehicleCardProps {
    vehicle: Vehicle;
}

export function ReservationVehicleCard( { vehicle }: ReservationVehicleCardProps ) {
    return <div className="mb-2 m-2">
        <div className="gap-1"
            style={{
                display: "grid",
                alignItems: "center", /* color make model */
                gridTemplateColumns: "min-content 1fr auto"
            }}>
            <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: vehicle.color }} />
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {vehicle.make} {vehicle.model}
                </div>
                <div>
                    {showStateName( getStateEnumFromCode( vehicle.stateCode )! )} {vehicle.licensePlateNumber}
                </div>
            </div>
        </div>
    </div>;
}

export function UserReservationDetailsPage() {
    const reserve    = useLoaderData() as Reservation;
    const facility   = reserve.facility;
    const totalPrice = reserve.price;
    return <Container>
        <Row>
            <Col>
                <PageTitle>
                    Reservation at {facility.name}
                </PageTitle>
                <Container className="g-0">
                    <Row className="mt-2 gap-4">
                        <Col className="border-end d-flex">
                            <div>
                                <img className="facility-result-pic"
                                    src={facilityImg( reserve.facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div>
                                <MapAddress address={facility.address}>{showAddressHtml( facility.address )}</MapAddress>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="d-flex align-items-center justify-content-start justify-content-md-center gap-4">
                                <div>
                                    <div>
                                        Check-In
                                    </div>
                                    <div className="fw-bold fs-4">
                                        {showTime( reserve.start.toLocalTime() )}
                                    </div>
                                    <div className="fs-6">
                                        {showDate( reserve.start.toLocalDate() )}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        Check-Out
                                    </div>
                                    <div className="fw-bold fs-4">
                                        {showTime( reserve.end.toLocalTime() )}
                                    </div>
                                    <div className="fs-6">
                                        {showDate( reserve.end.toLocalDate() )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <hr />
                {/* <Card>
                    <Card.Header className="fw-bold">
                        Vehicle
                    </Card.Header>
                    <Card.Body>
                        {<div className="text-secondary">
                            You may check-in with any of your vehicles.
                        </div>}
                        {reserve.vehicles.map( v => <ReservationVehicleCard key={v.vehicleId} vehicle={v} /> )}
                    </Card.Body>
                </Card> */}
                {/* <hr />
                <Card>
                    <Card.Header className="fw-bold">
                        Receipt
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row className="d-flex justify-content-center">
                                <Col xs="6">
                                    <div className="text-end">
                                        American Express ending in 9000.
                                    </div>
                                    <hr className="grid-col-span-2" />
                                    <div className="d-flex justify-content-end">
                                        <div style={{ display: "grid", gridTemplateColumns: "max-content max-content" }} className="gap-2">
                                            <div className="fw-bold">
                                                Total
                                            </div>
                                            <div className="fw-bold text-end">
                                                {showMoney( totalPrice )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card> */}
            </Col>
        </Row>
    </Container>
}
