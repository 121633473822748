//css
import './index.scss'
import './index.css';

//react
import React    from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";

//pages and components
import { App                          } from './App';
import { LandingPage                  } from "./LandingPage";
import { ResultsPage                  } from "./ResultsPage";
import { FacilityDetailsPage          } from "./FacilityDetailsPage";
import { CheckoutPageHourly           } from "./CheckoutPageHourly";
import { CheckoutPageMonthly          } from "./CheckoutPageMonthly";
import { AppUser                      } from "./AppUser";
import { UserProfilePage              } from "./UserProfilePage";
import { UserBillingPage              } from "./UserBillingPage";
import { UserVehiclesPage             } from "./UserVehiclesPage";
import { UserPlansPage                } from "./UserPlansPage";
import { UserAdvancedPage             } from "./UserAdvancedPage";
import { UserReservationsPage         } from "./UserReservationsPage";
import { SignUpPage                   } from "./SignUpPage";
import { LogInPage                    } from "./LogInPage";
import { ForgotPasswordPage           } from "./ForgotPasswordPage";
import { AboutPage                    } from "./AboutPage";
import { SandboxPage                  } from "./SandboxPage";
import { AtomDebugPage                } from "./AtomDebugPage";
import { UserReservationDetailsPage   } from "./UserReservationDetailsPage";
import { UserBillingDetailsPage       } from "./UserBillingDetailsPage";
import { UserBillingAddPage           } from "./UserBillingAddPage";
import { UserBillingAddSuccessPage } from "./UserBillingAddSuccessPage";
import { UserGroupPlansPage } from "./UserGroupPlansPage";
import { UserReservationReceiptPage } from "./UserReservationReceiptPage";
import { UserPlanDetailsPage } from "./UserPlanDetailsPage";
import { Api, PaymentMethodListResp } from "./Data/Api";
import { loadResult } from "./Data/Result";
import { parseFacility, parseFacilityParkerBalance, parseInvoice, parseOperator, parseOrder, parseOrderDetails, parseParker, parsePayment, parseReservation, parseSettleInitResp, parseSubscription } from "./Data/ApiParse";
import { Facility, Invoice, Order, OrderDetails, Payment, Reservation, Subscription, Vehicle } from "./Data/ApiTypes";
import { UserPlanDetailsChangePage } from "./UserPlanDetailsChangePage";
import { UserPaidPage } from "./UserPlanPaidPage";
import { UserOrdersPage } from "./UserOrdersPage";
import { UserOrderDetailsPage } from "./UserOrderDetailsPage";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { getDefaultStore } from "jotai";
import { authAtom, redirectAtom } from "./Data/Atoms";
import { SettleInitReqApi } from "./Data/ApiTransport";
import { UserSettlePage } from "./UserSettlePage";
import { UserPaymentsPage } from "./UserPaymentsPage";
import { UserInvoicesPage } from "./UserInvoicesPage";
import { UserInvoiceDetailsPage } from "./UserInvoiceDetailsPage";
import { ErrorPage } from "./ErrorPage";
import { UserPaymentDetailsPage } from "./UserPaymentDetailsPage";

function identity<T>( val: T ) { return val; }

const ldOperator: LoaderFunction<Facility> = async( { params } ) => {
    return Api.opDetails().then(
        res => loadResult(
            res,
            parseOperator,
            notLoggedInHandler
        ) );
}

const ldFacilities: LoaderFunction<Facility[]> = async( { params } ) => {
    return await Api.facilitySearch()
        .then( res => loadResult( res, res => res.map( parseFacility ), notLoggedInHandler ) );
}

function notLoggedInHandler( err: string ) {
    const store = getDefaultStore();
    if( err === "You are not logged in" ) {
        store.set( authAtom, { isLoggedIn: false } );
        return redirect( "/" );
    }
    return err;
}

// loader function for rate routes
const ldVehicles: LoaderFunction<Vehicle[]> =
    async ( { params } ) => await Api.vehicleList()
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );

const ldPaymentMethods: LoaderFunction<PaymentMethodListResp[]> =
    async( { params } ) => await Api.stripePaymentMethodList()
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );

const ldReservations: LoaderFunction<Reservation[]> =
    async( { params } ) => await Api.reservationList()
        .then( res => loadResult( res,
            val => val.map( parseReservation ),
            notLoggedInHandler ) );

const ldReservationDetails: LoaderFunction = async( { params } ) => {
    const { reservationId } = params;
    return await Api.reservationDetails( parseInt( reservationId! ) )
        .then( res => loadResult( res,
            parseReservation,
            notLoggedInHandler ) );
}

const ldSubs: LoaderFunction<Subscription[]> =
    async( { params } ) => await Api.subscriptionList()
        .then( res => loadResult( res,
            val => val.map( parseSubscription ),
            notLoggedInHandler ) );


const ldOrders: LoaderFunction<Order[]> = async( { params } ) => {
    return await Api.orderList()
        .then( res => loadResult( res,
            val => val.map( parseOrder ),
            notLoggedInHandler ) )
}

const ldOrderDetails: LoaderFunction<OrderDetails> = async( { params } ) => {
    const { orderId } = params;
    return await Api.orderDetails( parseInt( orderId! ) )
        .then( res => loadResult( res,
            val => parseOrderDetails( val ),
            notLoggedInHandler ) );
};

const ldInvoices: LoaderFunction<Invoice> = async( { params } ) => {
    return await Api.invoiceList()
        .then( res => loadResult( res,
            val => val.map( parseInvoice ),
            notLoggedInHandler ) );
};

const ldInvoiceDetails: LoaderFunction<Invoice> = async( { params, request } ) => {
    const { invoiceId } = params;
    const path  = new URL( request.url );
    const store = getDefaultStore();
    const resetCode = path.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, path.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    return await Api.invoiceDetails( parseInt( invoiceId! ) )
        .then( res => loadResult( res,
            parseInvoice,
            notLoggedInHandler ) );
}

const ldPayments: LoaderFunction<Payment[]> = async( { params } ) => {
    return await Api.paymentList()
        .then( res => loadResult( res,
            val => val.map( parsePayment ),
            notLoggedInHandler ) );
}

const ldPaymentDetails: LoaderFunction<Payment> = async( { params } ) => {
    const { paymentId } = params;
    return await Api.paymentDetails( parseInt( paymentId! ) )
        .then( res => loadResult( res,
            parsePayment,
            notLoggedInHandler ) );
}

const ldOrderDetailsForPay: LoaderFunction<OrderDetails> = async( { request, params } ) => {
    const { orderId } = params;
    const path  = new URL(request.url);
    const store = getDefaultStore();
    const resetCode = path.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, path.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    return await Api.orderDetails( parseInt( orderId! ) )
        .then( res => loadResult( res,
            parseOrderDetails,
            notLoggedInHandler ) );
};

const ldSettle: LoaderFunction<OrderDetails> = async( { request, params } ) => {
    const { facilityId } = params;
    const store = getDefaultStore();
    const url  = new URL(request.url);
    const resetCode = url.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, url.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    const init: SettleInitReqApi = { facilityId: parseInt( facilityId! ) };
    return Api.settleInit( init ).then(
        res => loadResult( res,
            parseSettleInitResp,
            notLoggedInHandler ) );
};

const ldResetCode: LoaderFunction = async( { request, params } ) => {
    const url = new URL( request.url );
    const store = getDefaultStore();
    const code = url.searchParams.get( "code" );
    return await Api.resetCodeCheck( { resetPasswordCode: code! } )
        .then( res => loadResult( res, parseParker,
            err => {
                if( err === "Invalid or expired reset code" ) {
                    var desiredUrl = store.get( redirectAtom );
                    var auth = store.get( authAtom );
                    if( auth.isLoggedIn && desiredUrl !== undefined ) {
                        return redirect( desiredUrl );
                    }
                    return redirect( "/" );
                }
            } ) );
}

const ldSubDetails: LoaderFunction<Subscription> = async( { params } ) => {
    const { planId } = params;
    return await Api.subscriptionDetails( parseInt( planId! ) )
        .then( res => loadResult( res,
            parseSubscription,
            notLoggedInHandler ) );
}

const ldPaymentMethodDetails: LoaderFunction = async( { params } ) => {
    const { methodId } = params;
    return await Api.stripePaymentMethodGet( methodId! )
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );
}

const ldBalances: LoaderFunction = async( { params } ) => {
    return await Api.facilityParkerBalanceList()
        .then( res => loadResult( res,
            val => val.map( parseFacilityParkerBalance ),
            notLoggedInHandler ) );
}

const router = createBrowserRouter( [ {
    path: "/", element: <App />, errorElement: <ErrorPage />, children: [
        //main parking stuff
        { path: "/",                                            element: <LandingPage             /> },
        { path: "/results",                                     element: <ResultsPage             />,
            loader: ldFacilities,
            shouldRevalidate: () => false,
            children: [ { path: "/results/:facilityId",         element: <ResultsPage /> }, ] },
        { path: "/checkout/hourly",                             element: <CheckoutPageHourly      /> },
        { path: "/checkout/monthly",                            element: <CheckoutPageMonthly     /> },
        { path: "/facility/:facilityId",                        element: <FacilityDetailsPage     /> },

        //user info
        { path: "/user",                                        element: <AppUser />, id: "/user", loader: ldBalances, children: [
            { path: "/user",                                    element: <UserProfilePage            /> },
            { path: "/user/profile",                            element: <UserProfilePage            /> }, //done

            //billing
            { path: "/user/billing",                            element: <UserBillingPage            />, loader: ldPaymentMethods }, //done
            { path: "/user/billing/:methodId",                  element: <UserBillingDetailsPage     />, loader: ldPaymentMethodDetails }, //done
            { path: "/user/billing/add",                        element: <UserBillingAddPage         /> },
            { path: "/user/billing/add/success",                element: <UserBillingAddSuccessPage  /> },
            { path: "/user/vehicle",                            element: <UserVehiclesPage           />, loader: ldVehicles     },

            //reservations
            { path: "/user/reservation",                        element: <UserReservationsPage       />, loader: ldReservations },
            { path: "/user/reservation/:time",                  element: <UserReservationsPage       />, loader: ldReservations },
            { path: "/user/reservation/details/:reservationId", element: <UserReservationDetailsPage />, loader: ldReservationDetails },
            { path: "/user/reservation/receipt/:reservationId", element: <UserReservationReceiptPage />, loader: ldReservationDetails },

            //finance and orders
            { path: "/user/invoice",                            element: <UserInvoicesPage           />, loader: ldInvoices       },
            { path: "/user/invoice/:invoiceId",                 element: <UserInvoiceDetailsPage     />, loader: ldInvoiceDetails },

            //payments
            { path: "/user/payment",                            element: <UserPaymentsPage           />, loader: ldPayments       },
            { path: "/user/payment/:paymentId",                 element: <UserPaymentDetailsPage     />, loader: ldPaymentDetails },

            //orders
            { path: "/user/order",                              element: <UserOrdersPage             />, loader: ldOrders         },
            { path: "/user/order/:orderId",                     element: <UserOrderDetailsPage       />, loader: ldOrderDetails   },

            //make payments
            { path: "/user/paid",                               element: <UserPaidPage               />, loader: undefined },
          //{ path: "/user/order/:orderId/pay",                 element: <UserOrderPayPage           />, loader: ldOrderDetailsForPay },
            { path: "/user/:facilityId/pay",                    element: <UserSettlePage             />, loader: ldSettle },

            //subscriptions
            { path: "/user/plan",                               element: <UserPlansPage              />, loader: ldSubs },
            { path: "/user/plan/:planId",                       element: <UserPlanDetailsPage        />, loader: ldSubDetails },
            { path: "/user/plan/:planId/edit",                  element: <UserPlanDetailsChangePage  />, loader: ldSubDetails },

            //etc
            { path: "/user/group",                              element: <UserGroupPlansPage         /> },
            { path: "/user/advanced",                           element: <UserAdvancedPage           /> },
        ] },

        //user
        { path: "/signup",                                      element: <SignUpPage              /> },
        { path: "/login",                                       element: <LogInPage               /> },
        { path: "/forgot",                                      element: <ForgotPasswordPage      /> },
        { path: "/reset",                                       element: <ResetPasswordPage       />, loader: ldResetCode },
        { path: "/about",                                       element: <AboutPage               />, loader: ldOperator  },

        //debug
        { path: "/sandbox",                                     element: <SandboxPage             /> },
        { path: "/atom",                                        element: <AtomDebugPage           /> },
        { path: "/framer",                                      element: <></> }
    ]
} ] );

const root = ReactDOM.createRoot(
    document.getElementById( 'root' ) as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
