import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData } from "react-router-dom";
import { Invoice } from "./Data/ApiTypes";
import { PageTitle } from "./Controls/PageTitle";
import { cents, showMoney } from "./Data/Money";
import { showInstantLocal } from "./Data/Date";

export function UserInvoiceDetailsPage() {
    const invoice = useLoaderData() as Invoice;
    const facility = invoice.facility;
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Invoice Details
                </PageTitle>
                <Container className="g-0">
                    <Row>
                        <Col xs={12} className="fs-4">
                            {facility.name}
                        </Col>
                        <Col className="fs-6">
                            <table className="table w-auto" cellPadding={4}>
                                <tr>
                                    <td>Starting Balance</td>
                                    <td>{showMoney( invoice.openingBalance )}</td>
                                </tr>
                                <tr>
                                    <td>Order Summary</td>
                                    <td>{showMoney( invoice.order.price )}</td>
                                </tr>
                                <tr>
                                    <td>Total Due</td>
                                    <td>{showMoney( invoice.endingBalance )}</td>
                                </tr>
                                <tr>
                                    <td>Email Sent</td>
                                    <td>{showInstantLocal( invoice.createdWhen )}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}