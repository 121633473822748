import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { MonthlySearch } from "./Controls/MonthlySearch";
import { HourlySearch } from "./Controls/HourlySearch";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { HourlyForm, MonthlyForm, hourlyFormAtom, monthlyFormAtom, tabAtom, SearchActivity, createHourlyForm, createMonthlyForm } from "./Data/Atoms";
import { Button } from "./Controls/Button";
import { Content } from "./Controls/Content";

interface ResetSubmitProps {
    reset:  () => void;
    submit: () => void;
}

function ResetSubmit( props: ResetSubmitProps ) {
    return <Container fluid>
        <Row>
            <Col xs="6">
                <Button
                    type="button"
                    className="w-100"
                    onClick={props.reset}>
                    Today
                </Button>
            </Col>
            <Col xs="6">
                <Button
                    type="submit"
                    onClick={props.submit}
                    className="w-100">
                    Search
                </Button>
            </Col>
        </Row>
    </Container>;
}

export function LandingPage() {
    const [ tab,     setTab     ] = useAtom( tabAtom         );
    const [ hourly,  setHourly  ] = useAtom( hourlyFormAtom  );
    const [ monthly, setMonthly ] = useAtom( monthlyFormAtom );

    const { register: regHr, getValues: getValuesHr, reset: resetHr, watch: watchHr, setValue: setValueHr,
            handleSubmit: handleSubmitHr, formState: formStateHr } = useForm<HourlyForm>(  { defaultValues: hourly  } );

    const { register: regMo, getValues: getValuesMo, reset: resetMo, watch: watchMo, setValue: setValueMo,
        handleSubmit: handleSubmitMo, formState: formStateMo } = useForm<MonthlyForm>( { defaultValues: monthly } );

    const vals = getValuesHr();
    // debugger;
    const nav = useNavigate();

    function reset() {
        setHourly(  { ...createHourlyForm(), location: getValuesHr("location") } );
        resetHr(    { ...createHourlyForm(), location: getValuesHr("location") } );

        setMonthly( { ...createMonthlyForm(), location: getValuesMo("location") } );
        resetMo(    { ...createMonthlyForm(), location: getValuesMo("location") } );
    }

    function submitHr() {
        setHourly( getValuesHr() );
        nav( "results" );
        return;
    }

    function submitMo() {
        setMonthly( getValuesMo() );
        nav( "results" );
    }

    return <Content fluid
                    id="landing-page"
                    className="fullheight g-0 g-sm-1">
        <Row className="fullheight justify-content-sm-center g-0">
            <Col sm="10" md="8" lg="6" xl="4"
                 className="fullheight d-flex align-items-top align-items-md-center "
                 style={{ minWidth: "40%" }}>
                <div id="landing-page-window" className="w-100 p-4 py-5 rounded-4">
                    <img width="150"
                         src="/img/logo-parkops.png"
                         alt="ParkOps Logo"
                         className="m-4"
                         style={{ justifySelf: "center" }} />
                    <Tabs defaultActiveKey="hourly"
                          activeKey={tab}
                          onSelect={k => setTab( k as SearchActivity )}
                          className="mb-3 w-100"
                          variant="underline"
                          justify>
                        <Tab eventKey="hourly" title="Hourly" onClick={() => setTab( SearchActivity.Hourly )}>
                            <Form noValidate onSubmit={handleSubmitHr(submitHr)}>
                                <HourlySearch
                                    register={regHr}
                                    watch={watchHr}
                                    setValue={setValueHr}
                                    errors={formStateHr.errors}
                                    setPlace={ () => 0 } />
                                <div className="mt-3">
                                </div>
                                <ResetSubmit reset={reset}
                                             submit={ () => {} } />
                            </Form>
                        </Tab>
                        <Tab eventKey="monthly" title="Monthly" onClick={() => setTab( SearchActivity.Monthly )}>
                            <Form noValidate onSubmit={handleSubmitMo(submitMo)}>
                                <MonthlySearch
                                    register={regMo}
                                    watch={watchMo}
                                    setValue={setValueMo}
                                    errors={formStateMo.errors}
                                    setPlace={ () => 0 } />
                                <ResetSubmit reset={reset}
                                             submit={ () => {} } />
                            </Form>
                        </Tab>
                    </Tabs>
                </div>
            </Col>
        </Row>
    </Content>;
}