import { Modal } from "react-bootstrap";
import { HeaderText } from "./HeaderText";
import { Button } from "./Button";
import { showStateName, getStateEnumFromCode } from "../Data/UsState";
import { Vehicle } from "../Data/ApiTypes";

export interface ChooseVehicleModalProps {
    vehicles: Vehicle[];
    show:     boolean;
    setShow:  ( b: boolean ) => void;
    onSubmit: ( v: Vehicle ) => void;
}

export function ChooseVehicleModal( props: ChooseVehicleModalProps ) {
    const { vehicles, show, setShow, onSubmit } = { ...props };

    function submit( v: Vehicle ) {
        onSubmit( v );
        setShow( false );
    }

    return <Modal show={props.show} centered>
        <Modal.Header>
            <HeaderText>Add Vehicle</HeaderText>
        </Modal.Header>
        <Modal.Body>
            <div className="text-secondary mb-2">
                Add one of your vehicles
            </div>
            <div className="d-flex flex-column gap-2">
                {vehicles.map( v =>
                    <Button key={v.vehicleId} className="w-100 text-start p-3" onClick={ () => submit( v ) }>
                        <div className="gap-1" style={{
                                display: "grid",
                                alignItems: "center", /* color make model */
                                gridTemplateColumns: "min-content 1fr auto"
                            }}>
                            <div className="rounded-2 border border-secondary me-2"
                                style={{ height: "32px", width: "32px", backgroundColor: v.color }} />

                            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                                <div className="fw-bold d-flex flex-column gap-1">
                                    {v.make} {v.model}
                                </div>
                                <div>
                                    {showStateName( getStateEnumFromCode( v.stateCode )! )} {v.licensePlateNumber}
                                </div>
                            </div>
                        </div>
                    </Button> )}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div>
                <Button type="button" className="w-100" onClick={() => setShow( false )}>
                    Cancel
                </Button>
            </div>
        </Modal.Footer>
    </Modal>;
}