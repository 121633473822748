import { Modal, Table } from "react-bootstrap";
import { showMoneyShort } from "../Data/Money";
import { showDuration } from "../Data/Date";
import { Button } from "./Button";
import { HeaderText } from "./HeaderText";
import { RateProgram as RateProgram } from "../Data/ApiTypes";
import { rateProgramType } from "../Data/RateProgram";

export interface RatesModalProps {
    show:    RateProgram[] | undefined;
    setShow: ( value: RateProgram[] | undefined ) => void;
}

export function RatesModal( props: RatesModalProps ) {
    const { show, setShow } = { ...props };
    return (
        <Modal show={!!show}
               centered
               keyboard
               onEscapeKeyDown={ () => setShow( undefined ) }>
            <Modal.Header>
                <HeaderText>Hourly Rates</HeaderText>
            </Modal.Header>
            <Modal.Body className="mt-0 pt-2">
                {show?.map( prog => {
                    const timeRange = rateProgramType( prog );
                    return <div className="mb-2">
                        <div className="fs-5 fw-bold mb-1">
                            {prog.name}
                        </div>
                        <Table key={prog.rateProgramId} striped hover bordered className="m-0 table table-compact">
                            <thead>
                                <tr>
                                    <th className="text-center">{timeRange}</th>
                                    <th className="text-center">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prog?.entries.map( rate => (
                                    <tr key={rate.rateEntryId}>
                                        <td className="text-center">Up to {showDuration( rate.upTo )}</td>
                                        <td className="text-center">
                                            {showMoneyShort( rate.rate )}
                                        </td>
                                    </tr>
                                ) )}
                            </tbody>
                        </Table>
                    </div>;
                } )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="w-25" onClick={() => setShow( undefined )}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
