import { useAtom } from "jotai";
import { Navbar, Nav } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { BootstrapBreakpointDebugger } from "./Controls/BootstrapBreakpointDebugger";
import { APIProvider        } from "@vis.gl/react-google-maps";
import { authAtom, maskAtom } from "./Data/Atoms";
import { Api                } from "./Data/Api";
import { apiServerName      } from "./Data/ApiUrl";
import { useEffect          } from "react";
import { processResult      } from "./Data/Result";
import { PageMask           } from "./PageMask";

function UserMenu() {
    const nav               = useNavigate();
    const [ auth, setAuth ] = useAtom( authAtom );

    useEffect( () => {
        Api.amILoggedIn().then( res => processResult( res, val => {
            //leave everything as is
        }, err => {
            console.log( err );
            setAuth( { isLoggedIn: false } );
        } ) );
    }, [] );

    function logOut(): void {
        Api.logOut().then( res => {
            if( res.isOk || (!res.isOk && res.error === "You are not logged in" ) ) {
                setAuth( { isLoggedIn: false } );
                return;
            }
            //else
            console.log( `Unexpected error when logging out: ${res.error}`)
        } );
    }

    if( auth.isLoggedIn === true
     && auth.account !== undefined ) {
        return <>
            <Nav.Link onClick={ () => nav( "/user" ) } className="ms-5 text-white fs-6" style={{ opacity: 1.0 }}>
                {auth.account.emailAddress}
            </Nav.Link>
            <Nav.Link onClick={ () => logOut() } className="ms-5 text-white fs-6" style={{ opacity: 1.0 }}>
                Log Out
            </Nav.Link>
        </>;
    }
    return <>
        <Nav.Link as={Link} to="/login" className="ms-5 text-white fs-6">
            Log <span style={{ letterSpacing: "0.075em" }}>I</span>n
        </Nav.Link>
        <Nav.Link as={Link} to="/signup" className="ms-5 text-white fs-6">
            Sign Up
        </Nav.Link>
    </>;
}

function TopMenu() {
    return (
        <Navbar collapseOnSelect
                className="px-5"
                expand="md"
                bg="black"
                variant="dark">
            <Navbar.Brand href="/" className="fs-5 d-flex align-items-center gap-2">
                <img src="/img/logo-spotsync.svg" style={{ width: "1.5em" }} /><span><span className="fw-bold">Spot</span>Sync</span>
            </Navbar.Brand>
            {apiServerName === "local" && <BootstrapBreakpointDebugger />}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav>
                    <Nav.Link as={Link} to="/about" className="ms-5 text-white fs-6">
                        About
                    </Nav.Link>
                    <UserMenu />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export function App() {

    return <APIProvider apiKey="AIzaSyBW7pTkR8xHrxMHXOQWGqXo4C518nbgNgo" libraries={["places"]}>
        <div id="main">
            <TopMenu />
            <div id="content">
                <Outlet />
            </div>
            <PageMask />
        </div>
    </APIProvider>;
}

export default App;
