import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { PlaceInput } from "./PlaceInput";

interface LocationInputProps {
    onPlaceSelect: ( place: google.maps.places.PlaceResult | null ) => void;
    error?:        FieldError | undefined;
}

export const LocationInput = forwardRef<HTMLInputElement, LocationInputProps>(
    ( props, ref ) => {
        const { onPlaceSelect, error, ...rest } = { ...props };
        return <div className="d-grid mb-0" style={{ gridTemplateColumns: "1fr", gridTemplateRows: "auto auto", gap: "0.25em" }}>
            <PlaceInput
                ref={ref}
                label="Search for Parking Near"
                explanation="Address or Zip Code"
                onPlaceSelect={onPlaceSelect}
                error={error}
                {...rest} />
        </div>;
    }
);
