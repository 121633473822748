import {
    ChangeEmailReqApi,
    ChangePasswordReqApi,
    FacilityApi,
    FacilityParkerBalanceApi,
    InvoiceApi,
    LogInReqApi,
    OperatorApi,
    OrderApi,
    OrderDetailsApi,
    OrderPayInitReqApi,
    OrderPayInitRespApi,
    ParkerApi,
    PaymentApi,
    ReservationApi,
    ReserveConfirmReqApi,
    ReserveInitReqApi,
    ReserveInitRespApi,
    ResetCodeCheckReqApi,
    ResetPasswordReqApi,
    SettleInitRespApi,
    SignUpReqApi,
    SubscribeConfirmReqApi,
    SubscribeConfirmRespApi,
    SubscribeInitReqApi,
    SubscribeInitRespApi,
    SubscriptionApi
} from "./ApiTransport";
import { ApiUrl } from "./ApiUrl";
import { OkResult, Result } from "./Result";
import { SettleInitReq, Vehicle } from "./ApiTypes";

export const notSet = ( a: any ) => a === null;

export type SignUpResp         = ParkerApi;
export type LogInResp          = ParkerApi;
export type ChgPassResp        = ParkerApi;
export type ChgEmailResp       = ParkerApi;
export type ResetPassResp      = ParkerApi;
export type ResetCodeCheckResp = ParkerApi;

export interface ForgotPassReq  { emailAddress: string; }
export type ForgotPassResp = string;

export interface StripeConfigResp {
    publishableKey: string;
    stripeAccount:  string;
};

export interface SetupIntentCreateResp {
    clientSecret:  string;
}

export interface PaymentIntentCreateResp {
    clientSecret:  string;
}

export type PaymentMethodListResp = PaymentMethodResp[];

export interface PaymentMethodResp {
    paymentMethodId: string;
    type:            string;
    card:            CardResp;
}

export interface CardResp {
    brand:           string;
    last4Digits:     string;
    expirationMonth: number;
    expirationYear:  number;
}

export interface VehicleData {
    make:               string;
    model:              string;
    color:              string;
    stateCode:          string;
    licensePlateNumber: string;
}

export class Api {
    private static getArgs(): RequestInit {
        return {
            method:      "GET",
            credentials: "include"
        }
    }

    private static jsonArgs( verb: string, payload: any ): RequestInit {
        return {
            method:      verb,
            headers:   { "Content-Type": "application/json" },
            body:        JSON.stringify( payload ),
            credentials: "include"
        }
    }

    private static postArgs    = ( payload: any ) => Api.jsonArgs( "POST",   payload );

    static resOk = <T>( value: T ): OkResult<T>          => ({ isOk: true, value: value })
    static ok    = <T>( value: T ): Promise<OkResult<T>> => Promise.resolve( this.resOk( value ) );

    static okDelay = <TErr, TVal>( value: TVal ): Promise<Result<TErr, TVal>> =>
        new Promise( ( resolve ) =>
            setTimeout( () => { resolve( this.resOk( value ) ); }, 500 )
    );

    static api<TErr, TVal>( url: string, init: RequestInit = {} ): Promise<Result<TErr, TVal>> {
        return fetch( url, init )
            .then( response => {
                const json   = response.json();
                const isOk = (response.status == 200);
                return json.then( data => {
                    if( isOk ) {
                        return { isOk: true, value: data };
                    }
                    return { isOk: false, error: data }
                } );
            } );
    }

    static api2<TErr, TValApi, TVal>( url: string, init: RequestInit = {}, f: (x: TValApi) => TVal ): Promise<Result<TErr, TVal>> {
        return fetch( url, init )
            .then( response => {
                const json   = response.json();
                const isOk = (response.status == 200);
                return json.then( data => {
                    if( isOk ) {
                        const parsed = f( data );
                        return { isOk: true, value: parsed };
                    }
                    return { isOk: false, error: data }
                } );
            } );
    }

    //operator
    static opDetails() { return this.api<string, OperatorApi>( ApiUrl.opDetails(), this.getArgs() ); }

    //auth
    static signUp(             req: SignUpReqApi         ) { return this.api<string, SignUpResp>        ( ApiUrl.signUp(),             this.postArgs( req ) ); }
    static logIn (             req: LogInReqApi          ) { return this.api<string, LogInResp>         ( ApiUrl.logIn(),              this.postArgs( req ) ); }
    static logOut(                                       ) { return this.api<string, LogInResp>         ( ApiUrl.logOut(),             this.postArgs( {}  ) ); }
    static changePassword(     req: ChangePasswordReqApi ) { return this.api<string, ChgPassResp>       ( ApiUrl.changePassword(),     this.postArgs( req ) ); }
    static changeEmailAddress( req: ChangeEmailReqApi    ) { return this.api<string, ChgEmailResp>      ( ApiUrl.changeEmailAddress(), this.postArgs( req ) ); }
    static forgotPassword(     req: ForgotPassReq        ) { return this.api<string, ForgotPassResp>    ( ApiUrl.forgotPassword(),     this.postArgs( req ) ); }
    static amILoggedIn(                                  ) { return this.api<string, LogInResp>         ( ApiUrl.amILoggedIn(),        this.getArgs(      ) ); }
    static resetPassword(      req: ResetPasswordReqApi  ) { return this.api<string, ResetPassResp>     ( ApiUrl.resetPassword(),      this.postArgs( req ) ); }
    static resetCodeCheck(     req: ResetCodeCheckReqApi ) { return this.api<string, ResetCodeCheckResp>( ApiUrl.resetCodeCheck(),     this.postArgs( req ) ); }

    //facilities
    static facilitySearch(                             ) { return this.api<string, FacilityApi[] >( ApiUrl.facilitySearch()      /* no need to log in */   ); }
    static facilityDetails( id: number                 ) { return this.api<string, FacilityApi   >( ApiUrl.facilityDetails( id ) /* no need to log in */   ); }

    //stripe
    static stripeConfig           ()             { return this.api<string, StripeConfigResp>     ( ApiUrl.stripeConfig(),                   this.postArgs( {} ) ); }
    static stripeSetupIntentCreate()             { return this.api<string, SetupIntentCreateResp>( ApiUrl.setupIntentCreate(),              this.postArgs( {} ) ); }
    static stripePaymentMethodList()             { return this.api<string, PaymentMethodListResp>( ApiUrl.stripePaymentMethodList(),        this.getArgs()      ); }
    static stripePaymentMethodGet ( id: string ) { return this.api<string, PaymentMethodResp    >( ApiUrl.stripePaymentMethodDetails( id ), this.getArgs()      ); }

    //vehicles
    static vehicleList()                      { return this.api<string, Vehicle[]>( ApiUrl.vehicleList(),      this.getArgs() );           }
    static vehicleGet( id: number )           { return this.api<string, Vehicle>  ( ApiUrl.vehicleDetails(id), this.getArgs() );           }
    static vehicleAdd( vehicle: VehicleData ) { return this.api<string, Vehicle>  ( ApiUrl.vehicleAdd(),       this.postArgs( vehicle ) ); }
    static vehicleDelete( id: number        ) { return this.api<string, Vehicle>  ( ApiUrl.vehicleDelete(id),  this.postArgs( {} ) );      }

    //reservations
    static reservationList()             { return this.api<string, ReservationApi[]> ( ApiUrl.reservationList(),      this.getArgs());    }
    static reservationDetails(id: number){ return this.api<string, ReservationApi>   ( ApiUrl.reservationDetails(id), this.getArgs());    }
    static reservationCancel(id: number) { return this.api<string, any>              ( ApiUrl.reservationCancel(id),  this.postArgs({})); }

    static reservationInit( reservationInit: ReserveInitReqApi ) {
        return this.api<string, ReserveInitRespApi>( ApiUrl.reservationInit(), this.postArgs( reservationInit ) ); }

    static reservationConfirm( reservationInit: ReserveConfirmReqApi ) {
        return this.api<string, ReserveInitRespApi>( ApiUrl.reservationConfirm(), this.postArgs( reservationInit ) ); }

    //plans and subscriptions
    static subscriptionInit( subscriptionInit: SubscribeInitReqApi ) {
        return this.api<string, SubscribeInitRespApi>( ApiUrl.subscriptionInit(), this.postArgs( subscriptionInit ) ); }

    static subscriptionConfirm( subscriptionConfirm: SubscribeConfirmReqApi ) {
        return this.api<string, SubscribeConfirmRespApi>( ApiUrl.subscriptionConfirm(), this.postArgs( subscriptionConfirm ) ); }

    static subscriptionList()                { return this.api<string, SubscriptionApi[]>( ApiUrl.subscriptionList(),   this.getArgs()      ); }
    static subscriptionDetails( id: number ) { return this.api<string, SubscriptionApi>(   ApiUrl.subscriptionDetails( id ), this.getArgs() ); }
    static planTermsGet( planId: number )    { return this.api<string, string>(            ApiUrl.planTerms( planId ), this.getArgs()       ); }

    //finance endpoints
    static facilityParkerBalanceList() { return this.api<string, FacilityParkerBalanceApi[]>( ApiUrl.facilityParkerBalanceList(), this.getArgs() )}
    static orderPayInit( req: OrderPayInitReqApi ) { return this.api<string, OrderPayInitRespApi>( ApiUrl.orderPayInit(), this.postArgs( req ) ); }
    static settleInit  ( req: SettleInitReq      ) { return this.api<string, SettleInitRespApi>(   ApiUrl.settleInit(),   this.postArgs( req ) ); }

    //orders
    static orderList()                  { return this.api<string, OrderApi[]>     ( ApiUrl.orderList(),      this.getArgs() ); }
    static orderDetails( id: number )   { return this.api<string, OrderDetailsApi>( ApiUrl.orderDetails(id), this.getArgs() ); }

    //invoices
    static invoiceList()                { return this.api<string, InvoiceApi[]> ( ApiUrl.invoiceList(),        this.getArgs() ); }
    static invoiceDetails( id: number ) { return this.api<string, InvoiceApi>   ( ApiUrl.invoiceDetails( id ), this.getArgs() ); }

    //payments
    static paymentList()                { return this.api<string, PaymentApi[]> ( ApiUrl.paymentList(),        this.getArgs() ); }
    static paymentDetails( id: number ) { return this.api<string, PaymentApi>   ( ApiUrl.paymentDetails( id ), this.getArgs() ); }
}