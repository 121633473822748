import { useParams  } from "react-router-dom";
import { showMoneyShort } from "./Data/Money";
import { showAddressHtml } from "./Data/Location";
import { Container } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";

export function FacilityDetailsPage() {
    return <></>;
    // const params     = useParams();
    // const facilityId = params.facilityId!;
    // const facility   = facilities[parseInt( facilityId )];
    // return <Container fluid>
    //     <div className="fs-1">{facility.name}</div>
    //     <div className="fs-2">{showAddressHtml( facility.address )}</div>
    //     <PageTitle>
    //         Parking Options
    //     </PageTitle>
    //     <div className="border rounded-4 p-4">
    //         <div style={{ display: "grid", width: "fit-content", gap: "1em", gridTemplateColumns: "auto auto auto" }}>
    //         {facility.plan.s.map( plan => <>
    //                 <div>
    //                     {plan.name}
    //                 </div>
    //                 <div>
    //                     {plan.description}
    //                 </div>
    //                 <div>
    //                     {showMoneyShort( plan.price.price )}
    //                 </div>
    //             </> )}
    //         </div>
    //     </div>
    // </Container>;
}