import { useAtom } from 'jotai';
import { maskAtom, PageMaskOpts } from './Data/Atoms';

export const PageMask = () => {
  const [maskOptions] = useAtom(maskAtom);

  if (!maskOptions?.showMask) {
    return null; // Do not render anything if mask is not visible
  }

  return (
    <div className="page-mask">
        <div className="mask-dialog">
        <div className="d-flex flex-column align-items-center">
                <h2 className="mt-3">{maskOptions.header}</h2>
                {maskOptions?.subText && (
                    <p>{maskOptions.subText}</p>
                )}
            </div>
            <br />
            <div className="mask-loader w-100">
                {/* Horizontal loader */}
            </div>
        </div>
    </div>
  );
};

