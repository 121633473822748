import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { BillingAddress } from "./Data/FakeData";
import { useEffect } from "react";

interface BillingAddressModalProps {
    show:    boolean;
    setShow: (show: boolean) => void;
    onSave:  (data: BillingAddress) => void;
}

export function BillingAddressModal( { show, setShow, onSave }: BillingAddressModalProps ) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<BillingAddress>( {
        defaultValues: { 
            streetAddress1: "", 
            streetAddress2: "", 
            city:           "",
            state:          "",
            zipCode:        ""
        }
    } );

    //reset the form each time it is shown
    useEffect( () => { if( show ) { reset(); } }, [show] );

    function submit(data: BillingAddress) {
        onSave( data );
        setShow( false );
    }

    return (
        <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header>
                    <HeaderText>Change Billing Address</HeaderText>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="text"
                        {...register("streetAddress1", { 
                            required: "Street address line 1 is required"
                        })}
                        label="Street Address"
                        error={errors.streetAddress1} />
                    <Input
                        type="text"
                        {...register("streetAddress2")}
                        label="Street Address Line 2"
                        error={errors.streetAddress2} />
                    <Input
                        type="text"
                        {...register("city", { 
                            required: "City is required"
                        })}
                        label="City"
                        error={errors.city} />
                    <Input
                        type="text"
                        {...register("state", { 
                            required: "State is required",
                            maxLength: 2,
                            pattern: {
                                value: /^[A-Z]{2}$/,
                                message: "Please enter a valid two-letter state code (e.g., CA)"
                            }
                        })}
                        label="State"
                        explanation="Enter the two-letter state code"
                        error={errors.state} />
                    <Input
                        type="text"
                        {...register("zipCode", { 
                            required: "Zip code is required",
                            pattern: {
                                value: /^\d{5}(-\d{4})?$/,
                                message: "Please enter a valid ZIP code (e.g., 12345 or 12345-6789)"
                            }
                        })}
                        label="Zip Code"
                        error={errors.zipCode} />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}