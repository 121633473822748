import { Button, ButtonProps } from "./Button";
import { Amenity } from "../Data/Amenity";

export interface AmenityButtonProps extends ButtonProps {
    amenities: Amenity[];
}

export function AmenitiesButton( props: AmenityButtonProps  ) {
    const { children, className, amenities, ...rest } = { ...props };
    const newClassName = `${className} rounded-4`;
    const arity        = amenities.length == 1 ? "Amenity" : "Amenities";
    const text         = amenities.length >  0 ? `${amenities.length} ${arity}` : children
    return <Button className={newClassName} {...rest}>
        {text}
    </Button>
}