import { ArrowLeftRight, Calendar2CheckFill, HouseDoorFill, KeyFill, PeopleFill, SignpostSplitFill, StopwatchFill         } from "react-bootstrap-icons";
import { Exclamation      } from "react-bootstrap-icons";
import { IconProps        } from "react-bootstrap-icons";
import { PersonLinesFill  } from "react-bootstrap-icons";
import { PersonWheelchair } from "react-bootstrap-icons";
import { StarFill         } from "react-bootstrap-icons";

export enum Amenity {
    Valet                = "Valet",
    GarageCovered        = "Covered",
    LotUncovered         = "Uncovered",
    OnSiteStaff          = "Staff",
    MonthToMonth         = "Monthly",
    WheelchairAccessible = "Handicap",
    ImmediateParking     = "Immediate",
    SelfPark             = "Self Park",
    Touchless            = "Touchless",
    InAndOut             = "In and Out"
}

export const allAmenities: Amenity[] = [
    Amenity.Valet,
    Amenity.GarageCovered,
    Amenity.LotUncovered,
    Amenity.OnSiteStaff,
    Amenity.MonthToMonth,
    Amenity.WheelchairAccessible,
    Amenity.ImmediateParking,
    Amenity.SelfPark,
    Amenity.Touchless,
    Amenity.InAndOut
];

interface AmenityIconProps extends IconProps {
    amenity: Amenity
}

// Function to map amenities to Bootstrap icons
export function AmenityIcon( props: AmenityIconProps ) {
    const div = <div></div>;
    const { amenity, ...rest } = props;
    switch( props.amenity ) {
        case Amenity.Valet:                return <KeyFill            { ...rest } />;
        case Amenity.GarageCovered:        return <HouseDoorFill      { ...rest } />;
        case Amenity.LotUncovered:         return <SignpostSplitFill  { ...rest } />;
        case Amenity.OnSiteStaff:          return <PeopleFill         { ...rest } />;
        case Amenity.MonthToMonth:         return <Calendar2CheckFill { ...rest } />;
        case Amenity.WheelchairAccessible: return <PersonWheelchair   { ...rest } />;
        case Amenity.ImmediateParking:     return <StopwatchFill      { ...rest } />;
        case Amenity.SelfPark:             return <PersonLinesFill    { ...rest } />;
        case Amenity.Touchless:            return <StarFill           { ...rest } />;
        case Amenity.InAndOut:             return <ArrowLeftRight     { ...rest } />;
        default:
            return <><Exclamation /> Err!</>;
    }
}